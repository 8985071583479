/**
 * Enum de exceções tratadas do sistema
 *
 * @author Bruno Eduardo
 */
enum ExceptionEnum {
  USUARIO_NAO_ENCONTRADO = 'USUARIO_NAO_ENCONTRADO',
  TOKEN_INVALIDO = 'TOKEN_INVALIDO',
  TOKEN_EXPIRADO = 'TOKEN_EXPIRADO',
  EMAIL_INEXISTENTE = 'EMAIL_INEXISTENTE',
  USUARIO_INATIVO = 'USUARIO_INATIVO',
  LOGIN_INVALIDO = 'LOGIN_INVALIDO',
  REFRESH_TOKEN_INVALIDO = 'REFRESH_TOKEN_INVALIDO',
  EMAIL_DUPLICADO = 'EMAIL_DUPLICADO',
  SENHA_ATUAL_INCORRETA = 'SENHA_ATUAL_INCORRETA'
}

export default ExceptionEnum;
