import { makeStyles } from '@material-ui/core';

/**
 * Estilos do componente do card que terá as fotos
 */
const useStyles = makeStyles({
  buttonOptions: {
    position: 'absolute',
    zIndex: 2,
    marginTop: 202,
    marginLeft: 194,
  },
  buttonEtiqueta: {
    position: 'absolute',
    zIndex: 2,
    marginTop: 17,
    marginLeft: 22,
  },
  containerIconCamera: {
    height: 22,
    width: 22,
    backgroundColor: '#2D2D2D',
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #0F0F0F',
    marginRight: 5,
    marginLeft: 5
  },
  containerFoto: {
    cursor: 'pointer',
    height: 240,
    width: 220,
    backgroundColor: '#1C1C1C',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#A5A5A5',
    flexDirection: 'column',
  },
  iconCamera: {
    width: 15,
    height: 12
  },
  iconMore: {
    height: 24,
    width: 24,
    marginRight: 10,
    color: '#777777',
    '&:hover': {
      color: '#00A8F9',
    }
  },
  itemFoto: {
    height: 168,
    width: 'calc(100% - 40px)',
    marginTop: 15,
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  cardContagemFoto: {
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex'
  },
  cardLabelFoto: {
    height: 33,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: 'calc(100% - 40px)',
    marginLeft: 10
  },
  cardIconLabel: {
    display: 'flex',
    margin: '10px 0px 10px 0px',
    width: '100%',
    justifyContent: 'space-between'
  },
  nomeFoto: {
    fontSize: 13,
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  descricaoFoto: {
    fontSize: 11,
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hoverFont: {
    cursor: 'pointer',
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  foto: {
    height: 180,
    width: 'calc(100% - 40px)',
    backgroundColor: '#A5A5A5',
    marginTop: 10
  },
  contagemFoto: {
    height: 25,
    width: 75,
    backgroundColor: '#2D2D2D',
    color: '#A5A5A5',
    border: '1px solid #0F0F0F',
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5
  },
  cardOptions: {
    fontSize: 14,
    position: 'absolute',
    background: '#000000',
    zIndex: 1000,
    marginTop: -190,
    marginLeft: 95,
    height: 190,
    width: 90,
    padding: 15
  },
});

export { useStyles };
