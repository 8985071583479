import React from 'react';
import { useStyles } from './cabecalho.styles';
import Logo from 'root-images/ARplay_logo.svg';

export type CabecalhoPropType = {};

/**
 * Componente para um cabeçalho com logo
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {CabecalhoPropType} props
 */
function Cabecalho(props: CabecalhoPropType): JSX.Element {
  const classes = useStyles(props);

  return (
    <div className={classes.card}>
      <img className={classes.logo} src={Logo} alt='Logomarca' />
    </div>
  );
}

export default Cabecalho;
