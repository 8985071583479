import { makeStyles } from '@material-ui/core';

/**
 * Estilos da view de dados do perfil
 **/
const useStyles = makeStyles({
  grid: {
    margin: '30px 50px',
    width: 'calc(100% - 100px)'
  },
  title: {
    color: '#A5A5A5',
    fontSize: 18,
    marginTop: 8
  },
  textField: {
    height: 70
  },
  iconFoto: {
    width: 30,
    height: 20,
    color: '#FFFFFF',
  },
  cardFoto: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:  'center',
    justifyContent: 'space-between',
    height: 70
  },
  foto: {
    cursor: 'pointer',
    height: 120,
    width: 120,
    backgroundColor: '#A5A5A5',
    borderRadius: 10,
    marginBottom: 15
  },
  file: {
    height: 120,
    width: 120,
    borderRadius: 10,
    objectFit: 'cover',
    overflow: 'hidden'
  },
  input: {
    color: '#FFFFFF !important',
    backgroundColor: '#1C1C1C !important',
    height: 35,
  },
  cardAutoComplete: {
    color: '#FFFFFF !important',
    backgroundColor: '#1C1C1C !important',
    borderRadius: 5
  },
  inputAutoComplete: {
    padding: '8.5px 14px',
    color: '#FFFFFF !important',
    backgroundColor: '#1C1C1C !important',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  labelButton: {
    width: 130,
    height: 35,
    color: '#A5A5A5',
    backgroundColor: '#000000',
    border: '1px solid #A5A5A5',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 11,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: '#04A6FC',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconPerson: {
    width: 120,
    height: 120,
    color: '#777777'
  },
  inputDisabled: {
    color: 'rgb(255, 255, 255, 0.5) !important',
    backgroundColor: 'rgb(0, 0, 0, 0.3) !important',
    height: 35
  },
  cardDisabledAutocomplete: {
    color: 'rgb(255, 255, 255, 0.5) !important',
    backgroundColor: 'rgb(0, 0, 0, 0.3) !important',
  },
  inputDisabledAutocomplete: {
    padding: '8.5px 14px',
  },
});

export { useStyles };
