import { makeStyles } from '@material-ui/core';

/**
 * Estilos da view de configuração do app
 **/
const useStyles = makeStyles({
  background: {
    height: 'calc(100vh - 425px)',
    backgroundColor: '#1C1C1C',
    margin: '4px 4px 4px 4px',
    padding: 30
  },
  lancamentos: {
    padding: '10px 70px',
    overflowY: 'scroll',
    height: 'calc(100% - 100px)'
  },
  gridItem: {
    height: 30,
  },
  subtitle: {
    marginTop: 10,
    color: '#F4F4F4',
    fontSize: 15
  },
  titleListe: {
    color: '#04A6FC',
    paddingLeft: 5
  },
  title: {
    color: '#A5A5A5',
    fontSize: 18,
    display: 'flex'
  },
  labelButton: {
    width: 150,
    height: 35,
    color: '#A5A5A5',
    backgroundColor: '#000000',
    border: '1px solid #A5A5A5',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 11,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: '#04A6FC',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    }
  },
  image: {
    width: '70%',
    height: '60%'
  },
  buttons: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  cardCartao: {
    width: 230,
    height: 50,
    border: '1px solid #00D693',
    marginRight: 40,
    color: '#F4F4F4',
    fontSize: 15,
    padding: '5px 5px 5px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  cardPagamentos: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center'
  },
  iconRow: {
    color: '#04A6FC',
    height: 24,
    width: 24
  },
  excluirCartao: {
    color: '#A5A5A5',
    fontSize: 13,
    marginTop: 20
  },
  divisor: {
    borderBottom: '1px solid #2D2D2D', 
    height: 30,
    marginBottom: 20
  },
  labelLancamentos: {
    fontSize: 15,
    height: 40
  },
  labelDate: {
    color: '#A5A5A5'
  },
  labelDescricao: {
    color: '#04A6FC',
  },
  labelValor: {
    color: '#F4F4F4'
  },
  labelVisualizar: {
    color: '#0BD690'
  }
});

export { useStyles };
