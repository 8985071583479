export default {
  modal: {
    criarNovoGrupo: 'Criar Novo Grupo',
    criarNovaColecao: 'Criar Nova Coleção',
    digiteNomeGrupo: 'Digite o Nome do Grupo',
    digiteNomeColecao: 'Digite o Nome da Coleção',
    observacaoGrupos: 'Crie GRUPOS, eles ajudam você a organizar as suas coleções.',
    criar: 'Criar',
    cancelar: 'Cancelar',
    campoObrigatorio: 'Campo obrigatório',
    erroSalvar: 'Ocorreu um erro',
    erroSalvarGrupo: 'Ocorreu um erro ao salvar o grupo'
  },
};
