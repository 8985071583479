export default {
  login: {
    campoObrigatorio: 'Required field',
    emailInvalido: 'Invalid email',
    minimoSenha: 'Password must be at least 6 characters',
    senha: 'Password *',
    retornarLogin: 'Back for Login',
    esqueceuSenha: 'Forgot password?',
    enviar: 'Submit',
    login: 'Login',
    falhaRealizarLogin: 'Login failed',
    usuarioSenhaIncorreto: 'Incorrect username or password',
    falhaClienteInativo: 'Client is inactive',
    falhaUsuarioInativo: 'User is inactive',
    falhaLoginMessage: 'Failed to login',
    emailEnviado: 'An email was sent with a link for you to change your password',
    emailEnviadoMessage: 'Email sent to ',
    usuarioNaoEncontrado: 'User not found',
    falhaRecuperarSenha: 'Failed to recover password',
  },
};
