export default {
  recuperarSenha: {
    campoObrigatorio: 'Required field',
    emailInvalido: 'Invalid email',
    minimoSenha: 'Password must be at least 6 characters',
    senha: 'Password *',
    retornarLogin: 'Back Login',
    confirmar: 'Change',
    senhasIncompativeis: 'Incompatible passwords',
    confirmarSenha: 'Password Confirm',
    codigo: 'Code',
    sucesso: 'Success',
    reencaminhar: 'You will be redirected to sign in',
    falhaRecuperarSenha: 'Failed to retrieve password',
    codigoExpirado: 'Expired code',
    codigoInvalido: 'Invalid code',
    falhaConectar: 'Failed to retrieve password',
  },
};
