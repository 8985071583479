import { DispatchAction } from 'root-states/root-dispatcher';
import DashboardLableActionTypes from 'root-states/actiontypes/dashboard-label-action-types';
import RootReducer from 'root-states/root-reducer';

/**
 * Modelo do estado deste Reducer
 */
export interface DashboardTitleStateType {
  label: string;
}

/**
 * Reducer do título da dashboard
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @class DashboardTitleReducer
 * @extends {RootReducer<DashboardTitleStateType>}
 */
class DashboardTitleReducer extends RootReducer<DashboardTitleStateType> {
  /**
   * Cria uma instância do Reducer e injeta o estado inicial
   */
  public constructor() {
    super({
      label: 'Todas as Mídeas',
    });
  }

  /**
   * Retorna a função Reducer do Redux
   *
   * @returns {(state: DashboardTitleStateType, action: DispatchAction) => DashboardTitleStateType}
   */
  public getReducer(): (state: DashboardTitleStateType, action: DispatchAction) => DashboardTitleStateType {
    return (state: DashboardTitleStateType = this.initialState, action: DispatchAction) => {
      switch (action.type) {
        case DashboardLableActionTypes.TITLE_TOOLBAR:
          return { ...state, label: action.payload ?? !state.label };

        default:
          return state;
      }
    };
  }
}

export default new DashboardTitleReducer().getReducer();
