import GenericApi from 'root-resources/generic-api';
import Municipio from 'root-models/municipio';
import { AxiosResponse } from 'axios';

/**
 * API do município
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @extends {RootApi}
 */
class MunicipioAPI extends GenericApi<Municipio> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao município
   */
  public constructor() {
    super({ baseEndpoint: '/municipio' });
  }

  /**
   * Busca um municipio pelo estado
   *
   * @param {T} id - ID do estado
   * @returns {Promise<AxiosResponse<Municipio[]>>} Promise com a lista de municipios
   */
  public async findMunicipioByUf(id: String): Promise<AxiosResponse<Municipio[]>> {
    return this.api.get<Municipio[]>(`/${id}`);
  }
}

export default MunicipioAPI;
