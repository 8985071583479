import React from 'react';
import {
  Help,
  PlayCircleFilled,
  StayCurrentPortrait,
  Lock,
  Person,
  MonetizationOn
} from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core';

/**
 * Modelo das definições de um item do menu lateral
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 */
export interface MenuLateralItemModel {
  label: string | JSX.Element;
  Icon?: (props: SvgIconProps) => JSX.Element;
  route?: string;
}

/**
 * Enum de cores de fundo dos itens do menu pra cada nível.
 * O primeiro nível são os filhos do primeiro item.
 */
export enum LevelColors {
  FIRST_LEVEL = 'rgb(0, 98, 43)',
  SECOND_LEVEL = 'rgb(0, 72, 32)'
}

/**
 * Lista de definições dos itens do menu lateral na aba Analytics
 */
const itensMenuLateralAnalytics: MenuLateralItemModel[] = [
  {
    label: 'Ajuda',
    Icon: props => <Help {...props} />,
    route: '/app/analytics/ajuda'
  },
  {
    label: 'Tutoriais',
    Icon: props => <PlayCircleFilled {...props} />,
    route: '/app/analytics/tutoriais'
  }
];

/**
 * Lista de definições dos itens do menu lateral na aba perfil
 */
const itensMenuLateralPerfil: MenuLateralItemModel[] = [
  {
    label: 'Seus dados',
    Icon: props => <Person {...props} />,
    route: '/app/perfil/dados'
  },
  {
    label: 'Alterar senha',
    Icon: props => <Lock {...props} />,
    route: '/app/perfil/senha'
  },
  {
    label: 'Planos e assinaturas',
    Icon: props => <MonetizationOn {...props} />,
    route: '/app/perfil/planos'
  },
  {
    label: 'App configuração',
    Icon: props => <StayCurrentPortrait {...props} />,
    route: '/app/perfil/configuracao'
  }
];

export default {
  itensMenuLateralPerfil,
  itensMenuLateralAnalytics
};
