import { makeStyles } from '@material-ui/core';

/**
 * Estilos da view de alterar senha
 **/
const useStyles = makeStyles({
  grid: {
    margin: '30px 50px',
  },
  textField: {
    height: 70
  },
  title: {
    color: '#A5A5A5',
    fontSize: 18
  },
  input: {
    color: '#FFFFFF !important',
    backgroundColor: '#1C1C1C !important',
    height: 35,
  },
  labelButton: {
    width: 130,
    height: 35,
    color: '#A5A5A5 !important',
    backgroundColor: '#000000 !important',
    border: '1px solid #A5A5A5 !important',
    borderRadius: '20px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '11px  !important',
    fontWeight: 300,
    '&:hover': {
      backgroundColor: '#04A6FC !important',
      color: '#FFFFFF !important',
      border: '1px solid #FFFFFF !important',
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export { useStyles };
