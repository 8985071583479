import React from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './card-ordenar.styles';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import Foto from 'root-models/foto';

export type CardOrdenacaoPropType = {
  clickClose: (event: number) => void;
  indexSelect: number;
};

/**
 * Componente card para ordenação da lista de fotos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {CardOrdenacaoPropType} props
 */
function CardOrdenacao(props: CardOrdenacaoPropType): JSX.Element {
  const classes = useStyles(props);

  const { clickClose, indexSelect } = props;
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const listFotos: Foto[] = useSelector<MainStateType, Foto[]>(state => {
    return state.appLayoutReducer.listFotosFiltered;
  });

  return (
    <div className={classes.cardOrdenacao}>
      ORDENAR POR
      <div className={classes.fontOrdenacao}>
        <p
          onClick={() => {
            let list = listFotos;

            list.sort(function (a, b) {
              return a.nmFoto < b.nmFoto ? -1 : a.nmFoto > b.nmFoto ? 1 : 0;
            });

            appLayoutActions.setMidiaToolbar(list);
            clickClose(1);
          }}
          style={{ color: indexSelect === 1 ? '#04A6FC' : '' }}
          className={classes.hoverFont}
        >
          A - Z nome
        </p>
        <div style={{ height: 20 }} />
        <p
          onClick={() => {
            let list = listFotos;

            list.sort(function (a, b) {
              return a.dtCriacao < b.dtCriacao ? -1 : a.dtCriacao > b.dtCriacao ? 1 : 0;
            });

            appLayoutActions.setMidiaToolbar(list);
            clickClose(2);
          }}
          style={{ color: indexSelect === 2 ? '#04A6FC' : '' }}
          className={classes.hoverFont}
        >
          data de criação
        </p>
        <div style={{ height: 20 }} />
        <p
          onClick={() => {
            let list = listFotos;

            list.sort(function (a, b) {
              return a.dtExpiracao < b.dtExpiracao ? -1 : a.dtExpiracao > b.dtExpiracao ? 1 : 0;
            });

            appLayoutActions.setMidiaToolbar(list);
            clickClose(3);
          }}
          style={{ color: indexSelect === 3 ? '#04A6FC' : '' }}
          className={classes.hoverFont}
        >
          data de expiração
        </p>
      </div>
      <div style={{ height: 20 }} />
      FILTRAR POR
      <div className={classes.fontOrdenacao}>
        <p className={classes.hoverFont}>mídias ativas</p>
        <div style={{ height: 20 }} />
        <p className={classes.hoverFont}>mídias inativas</p>
      </div>
    </div>
  );
}

export default CardOrdenacao;
