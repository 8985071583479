/**
 * Enum de ActionTypes para o título do card da dashboard
 *
 * @enum {number}
 */
enum DashboardLableActionTypes {
  TITLE_TOOLBAR = 'TITLE_TOOLBAR',
}

export default DashboardLableActionTypes;
