import { makeStyles } from '@material-ui/core';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente principal da aplicação
 */
const useStyles = makeStyles({
  rootListagem: {
    height: `calc(100vh - ${toolbarHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  containerDataTable: {
    marginBottom: 15,
    display: 'flex',
    height: '100%',
  },
  cardToolbar: {
    height: 50,
    width: 'calc(100% - 280px)',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    justifyContent: 'space-between',
    zIndex: 100,
    backgroundColor: '#0F0F0F'
  },
  todasMideas: {
    color: '#A5A5A5',
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 15,
    height: 50,
  },
  notes: {
    cursor: 'pointer',
    '&:hover': {
      color: '#04A6FC',
    }
  },
  containerOptions: {
    backgroundColor: '#242424',
    height: 36,
    display: 'flex',
    borderTop: '1px solid #0F0F0F',
    transitionDuration: '300ms',
    position: 'fixed',
    width: '100%',
    zIndex: 100,
  },
  optionsItem: {
    fontSize: 15,
    padding: 15,
    color: '#A5A5A5',
    border: '1px solid #0F0F0F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#242424',
    }
  },
  containerGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignContent: 'space-between',
  },

  conteudoModal: {
    padding: '40px 40px 80px 40px',
    color: '#F4F4F4',
    fontSize: 18
  },
  input: {
    color: '#777777 !important',
    backgroundColor: '#242424 !important',
    height: 30,
    width: 400
  },
  chip: {
    backgroundColor: '#2D2D2D',
    fontSize: 11,
    color: '#A5A5A5',
    height: 21,
    margin: 5,
    '&:hover': {
      backgroundColor: '#2D2D2D',
      color: '#A5A5A5',
    }
  },
  iconChip: {
    color: '#A5A5A5',
    width: 13,
    height: 13,
    marginRight: 5,
    '&:hover': {
      color: '#A5A5A5',
    }
  },
  iconEtiqueta: {
    width: 15,
    height: 12,
    cursor: 'pointer'
  },
  palavraChaveCard: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 1200,
  },
  palavraChave: {
    marginLeft: 'calc(100% - 560px)',
    fontSize: 18,
    color: '#F4F4F4',
    padding: 25,
    display: 'flex',
    width: 240,
    backgroundColor: '#242424',
    height: '100%',
    flexDirection: 'column'
  },
  itemPalavraChave: {
    color: '#A5A5A5',
    fontSize: 13,
    padding: '15px 0px',
    cursor: 'pointer',
    '&:hover': {
      color: '#FFFFFF',
    }
  },
  cardVazio: {
    color: '#F4F4F4',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '60vh',
    flexDirection: 'column'
  },
  cardObs: {
    color: '#A5A5A5',
    fontSize: 13,
    margin: '20px 0px',
    width: 225,
    display: 'flex',
    textAlign: 'center',
    height: 30,
    lineHeight: '20px'
  },
  buttonNovaMidia: {
    marginBottom: 10,
    marginTop: 10,
    margin: 5,
    padding: 15,
    cursor: 'pointer',
    height: 13,
    color: '#FFFFFF',
    backgroundColor: '#04A6FC',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  novaMidia: {
    fontSize: 18,
    marginLeft: 10,
    height: 21,
  },
});

export { useStyles };
