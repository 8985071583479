export default {
  confirmarCadastro: {
    campoObrigatorio: 'Required field',
    emailInvalido: 'Invalid email',
    minimoSenha: 'Password must be at least 6 characters',
    senha: 'Password *',
    senhasIncompativeis: 'Incompatible passwords',
    confirmarSenha: 'Password Confirm',
    codigo: 'Code',
    sucesso: 'Success',
    codigoInvalido: 'Invalid code',
    falhaConectar: 'Failed to retrieve password',
    confirmarCadastro: 'Confirm registration',
    falhaValidarCadastro: 'Failed to validate registration',
    cadastroJaConfirmado: 'Register already confirmed',
    cadastroConfirmado: 'Registration successfully confirmed',
  },
};
