import React, { useState } from 'react';
import { useStyles } from './app-configuracao.style';
import { Grid } from '@material-ui/core';
import Button from '@kepha/sumora-react-components/lib/button';
import { ChromePicker } from 'react-color';
import Arquivo from 'root-models/arquivo';
import UploadFiles from 'root-components/uploadfiles/upload-files';

const imageDownload = require('root-images/image_download.png');

export type AppConfiguracaoPropType = {};

/**
 * View de configuração do app
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function AppConfiguracao(props: AppConfiguracaoPropType): JSX.Element {
  const classes = useStyles();
  const [color, setColor] = useState('#FFF');
  const [fileLogo, setFileLogo] = useState<Arquivo>();
  const [fileIcone, setFileIcone] = useState<Arquivo>();

  return (
    <>
      <div className={classes.background}>
        <div className={classes.card}>
          <Grid item xs={12}>
            <div className={classes.title}>Logo do Aplicativo</div>
          </Grid>
          <div style={{ height: 30 }} />
          <Grid item xs={8} className={classes.cardFileName}>
            <UploadFiles
              propsInput={{
                accept: '.png, .jpg, .jpeg'
              }}
              multiUploads={false}
              key={1}
              addNewFiles={(fileList: Arquivo[]) => {
                setFileLogo(fileList[0]);
              }}
            >
              <Button
                className={classes.labelButton}
                CircularProgressProps={{ color: 'primary' }}
                onClick={() => {}}
              >
                Escolher imagem
              </Button>
            </UploadFiles>
            <div>{fileLogo?.dsFileName}</div>
          </Grid>
          <div style={{ height: 30 }} />
          <Grid item xs={12}>
            <img alt={'escolher-imagem'} src={imageDownload} className={classes.image} />
          </Grid>
        </div>
        <div className={classes.card}>
          <Grid item xs={12}>
            <div className={classes.title}>Ícone do aplicativo</div>
          </Grid>
          <div style={{ height: 30 }} />
          <Grid item xs={8} className={classes.cardFileName}>
            <UploadFiles
              propsInput={{
                accept: '.png, .jpg, .jpeg'
              }}
              multiUploads={false}
              key={1}
              addNewFiles={(fileList: Arquivo[]) => {
                setFileIcone(fileList[0]);
              }}
            >
              <Button
                className={classes.labelButton}
                CircularProgressProps={{ color: 'primary' }}
                onClick={() => {}}
              >
                Escolher imagem
              </Button>
            </UploadFiles>
            <div>{fileIcone?.dsFileName}</div>
          </Grid>
          <div style={{ height: 30 }} />
          <Grid item xs={12}>
            <img alt={'escolher-imagem'} src={imageDownload} className={classes.image} />
          </Grid>
        </div>
      </div>
      <div className={classes.background}>
        <div className={classes.card}>
          <Grid item xs={12}>
            <div className={classes.title}>Cor principal do aplicativo</div>
            <div className={classes.cardColors}>
              <ChromePicker
                disableAlpha={true}
                className={classes.backgroundColors}
                color={color}
                onChangeComplete={e => setColor(e.hex)}
              />
            </div>
          </Grid>
        </div>
        <div className={classes.card}>
          <div className={classes.buttons}>
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              onClick={() => {}}
            >
              Visualizar Preview
            </Button>
            <div style={{ height: 30 }} />
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              onClick={() => {}}
              style={{ width: 190 }}
            >
              Salvar personalização
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppConfiguracao;
