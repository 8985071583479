import React from 'react';
import { useStyles } from './card-foto.styles';
import Foto from 'root-models/foto';
import { MoreVert } from '@material-ui/icons/';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

const iconCamera = require('root-images/icon_camera.svg');
const iconEtiqueta = require('root-images/icon_etiqueta.svg');

export type CardFotoPropType = {
  item: Foto;
  changeStatusModal: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  clickIconMore: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  clickFoto: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  showOptionsCard: boolean;
  itemShowOptions: Foto | null;
  itemSelecionado: Foto | null;
};

const listDetalhes = ['excluir', 'mover', 'analytics', 'compartilhar', 'inativar', 'editar'];

/**
 * Componente de card para cada foto
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {CardFotoPropType} props
 */
function CardFoto(props: CardFotoPropType): JSX.Element {
  const classes = useStyles(props);
  const {
    item,
    changeStatusModal,
    clickIconMore,
    clickFoto,
    showOptionsCard,
    itemShowOptions,
    itemSelecionado
  } = props;

  return (
    <div>
      <div className={classes.buttonEtiqueta}>
        {item?.tpFoto === 0 && (
          <div
            className={classes.containerIconCamera}
            style={{ backgroundColor: colorIcon(item?.tpFoto) }}
            onClick={changeStatusModal}
          >
            <img src={iconEtiqueta} className={classes.iconCamera} alt='filtros' />
          </div>
        )}
      </div>
      <div className={classes.buttonOptions}>
        {itemSelecionado?.idFoto !== item.idFoto && (
          <MoreVert onClick={clickIconMore} className={classes.iconMore} />
        )}
      </div>
      <div
        onClick={clickFoto}
        className={classes.containerFoto}
        style={{
          border: itemSelecionado?.idFoto === item.idFoto ? '2px solid #04A6FC' : '2px solid #0F0F0F'
        }}
        key={item.idFoto}
      >
        <div className={classes.itemFoto}>
          <div className={classes.cardContagemFoto}>
            <Tooltip placement='left-end' title={mensagemTooltip(item?.tpFoto)} aria-label='add'>
              <div
                className={classes.containerIconCamera}
                style={{ backgroundColor: colorIcon(item?.tpFoto) }}
              >
                <img src={iconCamera} className={classes.iconCamera} alt='camera' />
              </div>
            </Tooltip>
          </div>

          <div className={classes.cardContagemFoto}>
            <div
              className={classes.contagemFoto}
              style={{
                backgroundColor: colorIcon(item?.tpFoto),
                color: item?.tpFoto !== 0 ? '#2D2D2D' : '#A5A5A5'
              }}
            >
              <div style={{ color: '#FFFFFF', paddingRight: 4 }}>20</div> /100{' '}
            </div>
          </div>
        </div>
        <div className={classes.foto} />
        <div className={classes.cardIconLabel}>
          <div className={classes.cardLabelFoto}>
            <div className={classes.nomeFoto}>{item.nmFoto}</div>
            <div className={classes.descricaoFoto}>
              {moment(item.dtCriacao).format('DD/MM/YYYY')}- Coleção Nome da determinada data
            </div>
          </div>
          {showOptionsCard && itemShowOptions?.idFoto === item.idFoto && (
            <div className={classes.cardOptions}>
              {listDetalhes.map(title => (
                <div key={title}>
                  <p className={classes.hoverFont}>{title} </p>
                  <div style={{ height: 20 }} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  /**
   * Retorna cor do icone em cima da foto de acordo com o status da foto
   *
   * @param tpFoto - status da foto
   */
  function colorIcon(tpFoto: number | undefined) {
    switch (tpFoto) {
      case 1:
        return '#FFB12A';
      case 2:
        return '#F62B6D';
      default:
        return '#2D2D2D';
    }
  }

  /**
   * Retorna mensagem de Tooltip do icone em cima da foto
   *
   * @param tpFoto - status da foto
   */
  function mensagemTooltip(tpFoto: number | undefined) {
    switch (tpFoto) {
      case 0:
        return 'projeto ativo';
      case 1:
        return 'período de edição expira em dois dias';
      case 2:
        return 'projeto inativo';
      default:
        return '';
    }
  }
}

export default CardFoto;
