import React, { useState } from 'react';
import viewsRoutes from 'root-views/views-routes';
import { Route, useHistory } from 'react-router-dom';
import { useStyles } from 'root-views/app.styles';
import MenuLateral from 'root-components/menulateral/menu-lateral';
import MainToolbar from 'root-components/maintoolbar/main-toolbar';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { Loading } from '@kepha/sumora-react-components/lib';
import { useComponentDidMount } from '@kepha/sumora-react-components/lib/utils/hooks';
import Authentication from 'root-resources/oauth/authentication';
import Admin from 'root-resources/oauth/admin';
import Modal from 'root-components/modal/modal';
import ModalColecaoGrupo from './modalcolecaogrupo/modal-colecao-grupo';

export type AppMainLayoutPropType = {};

/**
 * Componente que define o layout principal da aplicação e que chama a validação de login
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {AppMainLayoutPropType} props
 */
function AppMainLayout(props: AppMainLayoutPropType): JSX.Element {
  const classes = useStyles(props);
  const [modalOpen, setModalOpen] = useState(false);
  const [typeCard, setTypeCard] = useState(0);
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const history = useHistory();

  useComponentDidMount(() => {
    const oauth = new Authentication();
    const admin = new Admin();

    oauth
      .refreshToken()
      .then(() => admin.findPerfil().then())
      .catch(err => history.push('/login'));
  });

  return (
    <>
      <Loading show={isLoading} />

      <div className={classes.main}>
        <MenuLateral
          onClickColections={(type: number) => {
            setModalOpen(true);
            setTypeCard(type);
          }}
        />

        <div className={classes.containerToolbarAndRoutes}>
          <MainToolbar />

          <Modal
            heightModal={275}
            open={modalOpen}
            clickInsideModal={() => setModalOpen(false)}
            conteudoModal={
              <ModalColecaoGrupo typeCard={typeCard} closeModal={() => setModalOpen(false)} />
            }
          />

          <div className={classes.contentContainer}>
            {viewsRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exactPath} component={route.component} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AppMainLayout;
