import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import AppMainLayout from 'root-views';
import Login from 'root-views/login/login';
import RecuperarSenha from 'root-views/recuperarsenha/recuperar-senha';
import ConfirmarCadastro from 'root-views/confirmarcadastro/confirmar-cadastro';
import ConfirmarConta from 'root-views/confirmarconta/confirmar-conta';

/**
 * Cria e declara as rotas principais do App
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 */
function createRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/app' />} />
        <Route path='/login' component={Login} />
        <Route path='/resetar-senha' component={RecuperarSenha} />
        <Route path='/confirmar-cadastro' component={ConfirmarCadastro} />
        <Route path='/confirmar-conta/:id' component={ConfirmarConta} />
        <Route path='/app' component={AppMainLayout} />
        <Route path='*' render={() => <Redirect to='/app' />} />
      </Switch>
    </BrowserRouter>
  );
}

export default createRoutes;
