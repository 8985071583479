import { makeStyles } from '@material-ui/core';

/**
 * Estilos do componente de upload de files
 */
const useStyles = makeStyles(theme => ({
  cardFile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 15,
    cursor: 'pointer'
  },
  input: {
    display: 'none',
    cursor: 'pointer',
    float: 'left'
  }
}));

export { useStyles };
