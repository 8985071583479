export default {
  usuario: {
    usuarios: 'Usuarios',
    cadastroDeUsuario: 'Registro de usuario',
    edicaoDeUsuario: 'Edición de usuario',
    desejaDeletarEsteUsuario: '¿Quieres eliminar este usuario?',
    usuarioDeletadoComSucesso: 'Usuario eliminado con éxito',
    representante: 'Representante',
    gerente: 'Gerente',
    tecnico: 'Técnico',
    produtor: 'Productor',
    tipoDeUsuario: 'Tipo de usuario',
  },
};
