import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import createRoutes from 'root-views/main-routes';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from 'root-utils/constants';
import { Provider } from 'react-redux';
import Reducers from 'root-states';
import { createStore } from 'redux';

const routes = createRoutes();
const store = createStore(Reducers);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>{routes}</Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
