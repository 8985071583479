import React from 'react';
import { useStyles } from './modal.styles';

export type ModalPropType = {
  clickInsideModal: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  open: boolean;
  conteudoModal: JSX.Element;
  heightModal?: number;
};

/**
 * Componente de modal
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {ModalPropType} props
 */
function Modal(props: ModalPropType): JSX.Element {
  const classes = useStyles(props);
  const { clickInsideModal, open, conteudoModal, heightModal } = props;

  return (
    <>
      {open ? (
        <div className={classes.backgroundModal}>
          <div className={classes.backgroundModalClick} onClick={clickInsideModal} />

          <div style={{ height: heightModal || undefined }} className={classes.cardModal}>
            {conteudoModal}
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
}

export default Modal;
