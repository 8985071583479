import React from 'react';
import { MenuLateralItemModel } from './menu-itens-definitions';
import { useStyles } from './menu.styles';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from 'react-router-dom';

export type MenuLateralItemPropType = {
  /**
   * Definições do item que vai ser renderizado nesse componente
   */
  item: MenuLateralItemModel;
};

/**
 * Representa um item do menu lateral, em qualquer nível
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {MenuLateralItemPropType} props
 */
function MenuLateralItem(props: MenuLateralItemPropType): JSX.Element {
  const classes = useStyles(props);
  const { item } = props;

  const history = useHistory();

  return (
    <>
      <ListItem
        button
        className={classes.containerItem}
        onClick={() => {
          if (item.route) {
            history.push(item.route);
          }
        }}
        style={{
          backgroundColor: history.location.pathname === item.route ? '#242424' : undefined
        }}
      >
        {item.Icon && (
          <div
            style={{
              color: history.location.pathname === item.route ? '#04A6FC' : undefined
            }}
            className={classes.itemIcon}
          >
            {item.Icon({ fontSize: 'default' })}
          </div>
        )}

        <div
          style={{
            color: history.location.pathname === item.route ? '#F4F4F4' : undefined
          }}
          className={classes.label}
        >
          {item.label}
        </div>
      </ListItem>
    </>
  );
}

export default MenuLateralItem;
