import React, { useState } from 'react';
import Arquivo from 'root-models/arquivo';
import Swal from 'root-components/swal/swal';
import { useStyles } from './upload-files.style';

export type ExitConfirmationPropType = {
  addNewFiles: (event: Arquivo[]) => void;
  children: any;
  multiUploads: boolean;
  propsInput?: any;
  disabled?: boolean;
};

/**
 * Componente para seleção de arquivos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function UploadFiles(props: ExitConfirmationPropType): JSX.Element {
  const classes = useStyles(props);
  const [inputFilesRef, setInputFilesRef] = useState<HTMLDivElement | null>(null);

  return (
    <div
      className={classes.cardFile}
      onChange={(e: any) => {
        onAddFiles(e, e.target.files || []);
      }}
      onClick={(e: any) => {
        if (inputFilesRef && !props.disabled) {
          inputFilesRef.click();
        }
      }}
      onDragOver={e => e.preventDefault()}
    >
      {props.children}
      <input
        {...props.propsInput}
        multiple={props.multiUploads}
        className={classes.input}
        type='file'
        ref={ref => {
          setInputFilesRef(ref);
        }}
      />
    </div>
  );

  /**
   * Faz o tratamento do arquivo adicionado
   * @param e - arquivo adicionado
   * @param files - arquivos existentes
   */
  async function onAddFiles(e: any, files: any) {
    if (files.length === 0) return;

    e.preventDefault();

    let auxFiles: Arquivo[] = [];
    let allValid = true;

    const promises = Array.from(files).map(async (file: any) => {
      return await fileToBase64(file)
        .then((dsBase64: any) => {
          const fileName = file.name.replaceAll(' ', '');
          const tpArquivo = file.name.split('.')[1];

          auxFiles.push({
            dsArquivo: dsBase64,
            dsFileName: fileName,
            dsUrlAquivo: null,
            dsBase64: dsBase64.split(';base64,')[1],
            stSituacao: 0,
            tpArquivo: tpArquivo.toLowerCase()
          });
        })
        .catch(err => {
          allValid = false;
        });
    });

    await Promise.all(promises);

    if (allValid) {
      props.addNewFiles(auxFiles);
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Ecorreu um erro',
        text: 'Falha ao adicionar arquivo',
        icon: 'error'
      });
    }
  }

  /**
   * Gera um promise para converter arquivo pra base 64
   *
   * @param file arquivo
   */
  function fileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      if (file.size <= 20000000) {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file);
      } else {
        reject();
      }
    });
  }
}

export default UploadFiles;
