import GenericApi from 'root-resources/generic-api';
import Uf from 'root-models/uf';

/**
 * API do Uf
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @extends {RootApi}
 */
class UfAPI extends GenericApi<Uf> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Uf
   */
  public constructor() {
    super({ baseEndpoint: '/uf' });
  }
}

export default UfAPI;
