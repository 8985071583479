import { makeStyles } from '@material-ui/core';

/**
 * Estilos do componente de card para ordenação dos itens
 */
const useStyles = makeStyles({
  cardOrdenacao: {
    fontSize: 11,
    position: 'fixed',
    background: '#000000',
    zIndex: 1000,
    marginTop: 280,
    marginLeft: 165,
    height: 225,
    width: 170,
    padding: 15
  },
  fontOrdenacao: {
    fontSize: 14,
    marginTop: 20,
    marginLeft: 15
  },
  hoverFont: {
    cursor: 'pointer',
    '&:hover': {
      color: '#FFFFFF'
    }
  },
});

export { useStyles };
