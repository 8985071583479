import { makeStyles } from '@material-ui/core';

/**
 * Estilos do cabeçalho
 **/
const useStyles = makeStyles({
  card: {
    zIndex: 1000,
    display: 'flex',
    width: 440,
    height: 70,
    flexDirection: 'column',
    borderRadius: 10,
    padding: 0,
    marginLeft: 50
  },
  logo: {
    fontSize: 20,
    color: '#000000',
    fontWeight: 500,
    alignItems: 'center',
    width: 110,
    marginTop: 10
  }
});

export { useStyles };
