import React, { useState, useEffect, useCallback } from 'react';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import ptBR from './../i18n/ptBR';
import enUS from './../i18n/enUS';
import esES from './../i18n/esES';
import { useStyles } from './dados-perfil.style';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, MaskedTextField } from '@kepha/sumora-react-components/lib';
import { Grid } from '@material-ui/core';
import AutoComplete from '@kepha/sumora-react-components/lib/autocomplete';
import Button from '@kepha/sumora-react-components/lib/button';
import { CameraAlt, Person } from '@material-ui/icons';
import CPFValidator from 'root-validator/cpf-validator';
import UploadFiles from 'root-components/uploadfiles/upload-files';
import Arquivo from 'root-models/arquivo';
import Uf from 'root-models/uf';
import Swal from 'root-components/swal/swal';
import Admin from 'root-resources/oauth/admin';
import { useDispatch } from 'react-redux';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import Municipio from 'root-models/municipio';
import UfAPI from 'root-resources/api/uf';
import MunicipioAPI from 'root-resources/api/municipio';
import ClienteAPI from 'root-resources/api/cliente';
import Cliente from 'root-models/cliente';

/**
 * Tipo dos valores do formik
 */
export type DadosPerfilFormikValuesType = {
  idCliente: string;
  nmCliente: string;
  dsEmail: string;
  nrCpf: string;
  nrRg: string;
  nmEmpresa: string;
  dsLogradouro: string;
  dsBairro: string;
  municipio: Municipio | undefined;
  uf: Uf | undefined;
  nrCep: string;
  nrTelefone: string;
  dsFoto?: string;
  dsFotoBase64?: string;
};

export type DadosPerfilPropType = {};

/**
 * View Dados Perfil
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function DadosPerfil(props: DadosPerfilPropType): JSX.Element {
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES });
  const classes = useStyles(props);
  const [arquivo, setArquivo] = useState<Arquivo>();
  const adminAPI = new Admin();
  const municipioAPI = new MunicipioAPI();
  const clienteAPI = new ClienteAPI();
  const ufAPI = new UfAPI();
  const history = useHistory();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const [ufList, setUfList] = useState<Uf[]>([]);
  const [municipioList, setMunicipioList] = useState<Municipio[]>([]);
  const [keyAutoComplete, setKeyAutoComplete] = useState(0);
  const [widthAutoComplete, setWidthAutoComplete] = useState(0);
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<DadosPerfilFormikValuesType>({
    idCliente: '',
    nmCliente: '',
    dsEmail: '',
    nrCpf: '',
    nrRg: '',
    nmEmpresa: '',
    dsLogradouro: '',
    dsBairro: '',
    municipio: undefined,
    uf: undefined,
    nrCep: '',
    nrTelefone: ''
  });

  useEffect(() => {
    if (document.getElementById('grid')?.offsetWidth) {
      setWidthAutoComplete(document.getElementById('grid')?.offsetWidth || 0);
    }
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues,
    enableReinitialize,
    validationSchema: Yup.object().shape({
      nmCliente: Yup.string().required(formatMessage('dadosPerfil.campoObrigatorio')),
      dsEmail: Yup.string().required(formatMessage('dadosPerfil.campoObrigatorio')),
      nrCpf: Yup.string()
        .test('', formatMessage('dadosPerfil.cpfInvalido'), function (value) {
          if (value !== undefined) {
            return CPFValidator(value);
          }
          return true;
        })
        .required(formatMessage('dadosPerfil.campoObrigatorio')),
      nrRg: Yup.string().required(formatMessage('dadosPerfil.campoObrigatorio')),
      nmEmpresa: Yup.string().required(formatMessage('dadosPerfil.campoObrigatorio')),
      dsLogradouro: Yup.string().required(formatMessage('dadosPerfil.campoObrigatorio')),
      dsBairro: Yup.string().required(formatMessage('dadosPerfil.campoObrigatorio')),
      municipio: Yup.object().nullable().required(formatMessage('dadosPerfil.campoObrigatorio')),
      uf: Yup.object().nullable().required(formatMessage('dadosPerfil.campoObrigatorio')),
      nrTelefone: Yup.string()
        .min(13, formatMessage('dadosPerfil.telefoneInvalido'))
        .required(formatMessage('dadosPerfil.campoObrigatorio')),
      nrCep: Yup.string()
        .min(9, formatMessage('dadosPerfil.cepInvalido'))
        .required(formatMessage('dadosPerfil.campoObrigatorio'))
    }),
    onSubmit: () => submit(),
    validateOnBlur: true,
    validateOnChange: false
  });

  const findItensUser = () => {
    setEnableReinitialize(true);
    appLayoutActions.setLoading(true);

    ufAPI.findAll().then(doc => {
      setUfList(doc.data);
    });

    adminAPI
      .findPerfil()
      .then(doc => {
        clienteAPI
          .findClienteByEmail(doc.dsEmail)
          .then(item => {
            tratarFindById(item.data);
          })
          .catch(err => {
            tratarMensagensErro(formatMessage('dadosPerfil.ocorreuUmErroBuscarUsuario'));
          })
          .finally(() => {
            setEnableReinitialize(false);
            appLayoutActions.setLoading(false);
          });
      })
      .catch(err => {
        setEnableReinitialize(false);
        appLayoutActions.setLoading(false);

        tratarMensagensErro(formatMessage('dadosPerfil.ocorreuUmErroBuscarUsuario'));
      });
  };

  const findItensUserAux = useCallback(findItensUser, []);
  useEffect(() => findItensUserAux(), [findItensUserAux]);

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} className={classes.title}>
          {formatMessage('dadosPerfil.dadosPessoais')}
        </Grid>

        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='nmCliente'
            placeholder={formatMessage('dadosPerfil.nomeCompleto')}
            value={values.nmCliente}
            onChange={value => {
              setFieldValue('nmCliente', value.target.value);
            }}
            onBlur={handleBlur}
            error={touched.nmCliente && errors.nmCliente !== undefined}
            helperText={touched.nmCliente && errors.nmCliente !== '' ? errors.nmCliente : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={5} className={classes.textField}>
          <MaskedTextField
            placeholder={formatMessage('dadosPerfil.cpf')}
            typeMask={4}
            value={values.nrCpf}
            onChange={value => {
              setFieldValue('nrCpf', value.target.value);
            }}
            error={touched.nrCpf && errors.nrCpf !== undefined}
            helperText={touched.nrCpf && errors.nrCpf !== '' ? errors.nrCpf : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={2} className={classes.cardFoto}>
          <UploadFiles
            propsInput={{
              accept: '.png, .jpg, .jpeg'
            }}
            multiUploads={false}
            key={1}
            addNewFiles={(fileList: Arquivo[]) => {
              setArquivo(fileList[0]);
            }}
          >
            <div className={classes.foto}>
              {!arquivo && <Person className={classes.iconPerson} />}
              {arquivo && (
                <img
                  id={arquivo.dsFileName}
                  alt={arquivo.dsFileName}
                  src={arquivo.dsArquivo}
                  className={classes.file}
                />
              )}
            </div>
            <CameraAlt className={classes.iconFoto} />
          </UploadFiles>
        </Grid>

        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='dsEmail'
            disabled
            placeholder={formatMessage('dadosPerfil.email')}
            value={values.dsEmail}
            type={'email'}
            onChange={e => {
              setFieldValue('dsEmail', e.target.value);
            }}
            onBlur={handleBlur}
            error={touched.dsEmail && errors.dsEmail !== undefined}
            helperText={touched.dsEmail && errors.dsEmail !== '' ? errors.dsEmail : null}
            variant='outlined'
            InputProps={{
              className: classes.inputDisabled
            }}
          />
        </Grid>

        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='nrRg'
            placeholder={formatMessage('dadosPerfil.rg')}
            value={values.nrRg}
            onChange={value => {
              setFieldValue('nrRg', value.target.value);
            }}
            onBlur={handleBlur}
            error={touched.nrRg && errors.nrRg !== undefined}
            helperText={touched.nrRg && errors.nrRg !== '' ? errors.nrRg : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='nmEmpresa'
            placeholder={formatMessage('dadosPerfil.nomeEmpresa')}
            value={values.nmEmpresa}
            onChange={value => {
              setFieldValue('nmEmpresa', value.target.value);
            }}
            onBlur={handleBlur}
            error={touched.nmEmpresa && errors.nmEmpresa !== undefined}
            helperText={touched.nmEmpresa && errors.nmEmpresa !== '' ? errors.nmEmpresa : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={5} className={classes.textField}>
          <MaskedTextField
            placeholder={formatMessage('dadosPerfil.telefone')}
            typeMask={0}
            value={values.nrTelefone}
            onChangeValue={value => {
              setFieldValue('nrTelefone', value);
            }}
            error={touched.nrTelefone && errors.nrTelefone !== undefined}
            helperText={touched.nrTelefone && errors.nrTelefone !== '' ? errors.nrTelefone : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={5} />

        <Grid item xs={12} className={classes.title}>
          {formatMessage('dadosPerfil.endereco')}
        </Grid>

        <Grid item xs={3} className={classes.textField}>
          <MaskedTextField
            id='nrCep'
            typeMask={3}
            placeholder={formatMessage('dadosPerfil.cep')}
            value={values.nrCep}
            onChange={value => {
              setFieldValue('nrCep', value.target.value.replace(/\s/g, ''));
            }}
            onBlur={handleBlur}
            error={touched.nrCep && errors.nrCep !== undefined}
            helperText={touched.nrCep && errors.nrCep !== '' ? errors.nrCep : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={4} className={classes.textField} key={keyAutoComplete + 1}>
          <AutoComplete<Uf>
            stylesAdornment={{ color: 'rgb(165, 165, 165, 0.8)' }}
            classNameAdditional={classes.cardAutoComplete}
            inputProps={{
              variant: 'outlined',
              placeholder: formatMessage('dadosPerfil.estado'),
              inputProps: {
                className: classes.inputAutoComplete
              }
            }}
            limitSuggestions={7}
            suggestions={ufList}
            getLabel={item => item.nmUf}
            getValue={item => item.idUf}
            onChangeValue={(value: Uf | null) => onChangeValueUf(value)}
            onBlur={handleBlur}
            value={values.uf || undefined}
            styleInput={{ color: '#FFFFFF !important' }}
            error={touched.uf && errors.uf !== undefined}
            errorText={touched.uf && errors.uf !== undefined ? `${errors.uf}` : ''}
          />
        </Grid>

        <Grid id='grid' item xs={5} className={classes.textField} key={keyAutoComplete}>
          <AutoComplete<Municipio>
            disabled={!values.uf}
            stylesAdornment={{ color: 'rgb(165, 165, 165, 0.8)' }}
            classNameAdditional={
              !values.uf ? classes.cardDisabledAutocomplete : classes.cardAutoComplete
            }
            inputProps={{
              variant: 'outlined',
              placeholder: formatMessage('dadosPerfil.cidade'),
              inputProps: {
                className: !values.uf ? classes.inputDisabledAutocomplete : classes.inputAutoComplete
              }
            }}
            suggestions={municipioList}
            limitSuggestions={7}
            getLabel={item => item.nmMunicipio}
            getValue={item => item.idMunicipio}
            onChangeValue={value => {
              setFieldValue('municipio', value);
            }}
            onBlur={handleBlur}
            value={values.municipio || undefined}
            styleSuggestions={{
              width: widthAutoComplete
            }}
            styleInput={{ color: '#FFFFFF !important' }}
            error={values.uf && touched.municipio && errors.municipio !== undefined}
            errorText={
              values.uf && touched.municipio && errors.municipio !== undefined
                ? `${errors.municipio}`
                : ''
            }
          />
        </Grid>

        <Grid item xs={7} className={classes.textField}>
          <TextField
            id='dsLogradouro'
            placeholder={formatMessage('dadosPerfil.endereco')}
            value={values.dsLogradouro}
            onChange={value => {
              setFieldValue('dsLogradouro', value.target.value);
            }}
            onBlur={handleBlur}
            error={touched.dsLogradouro && errors.dsLogradouro !== undefined}
            helperText={touched.dsLogradouro && errors.dsLogradouro !== '' ? errors.dsLogradouro : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='dsBairro'
            placeholder={formatMessage('dadosPerfil.bairro')}
            value={values.dsBairro}
            onChange={value => {
              setFieldValue('dsBairro', value.target.value);
            }}
            onBlur={handleBlur}
            error={touched.dsBairro && errors.dsBairro !== undefined}
            helperText={touched.dsBairro && errors.dsBairro !== '' ? errors.dsBairro : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.buttons}>
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              style={{ width: 100 }}
              onClick={() => history.push('/app')}
            >
              Cancelar
            </Button>

            <div style={{ width: 35 }} />

            <Button
              loading={loading}
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              onClick={() => handleSubmit()}
              style={{ width: 150 }}
            >
              Salvar alterações
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  function onChangeValueUf(value: Uf | null) {
    setFieldValue('uf', value);
    setFieldValue('municipio', null);
    setFieldError('municipio', '');
    setFieldTouched('municipio', false);
    setKeyAutoComplete(Math.random());
    if (value) {
      municipioAPI.findMunicipioByUf(value.idUf).then(doc => {
        setMunicipioList(doc.data);
      });
    } else {
      setMunicipioList([]);
    }
  }

  /**
   * Altera o nome e a senha do usuário
   */
  function submit() {
    setLoading(true);
    values.nrTelefone = values.nrTelefone.replace(' ', '');

    if (arquivo?.dsFileName) {
      values.dsFotoBase64 = arquivo?.dsBase64;
      values.dsFoto = arquivo?.dsFileName;
    } else if (arquivo) {
      values.dsFoto = arquivo?.dsBase64;
    }

    clienteAPI
      .update(values)
      .then(() => {
        setLoading(false);

        tratarMensagemSucesso();
      })
      .catch(err => {
        setLoading(false);
        tratarMensagensErro(formatMessage('dadosPerfil.ocorreuErroEditarUsuario'));
      });
  }

  /**
   * Trata as informações do usuario do
   */
  function tratarFindById(cliente: Cliente) {
    setInitialValues({
      idCliente: cliente.idCliente,
      nmCliente: cliente.nmCliente,
      dsEmail: cliente.dsEmail,
      nrCpf: cliente.nrCpf || '',
      nrRg: cliente.nrRg || '',
      nmEmpresa: cliente.nmEmpresa || '',
      dsLogradouro: cliente.dsLogradouro || '',
      dsBairro: cliente.dsBairro || '',
      municipio: cliente.municipio || undefined,
      uf: cliente.uf || undefined,
      nrCep: cliente.nrCep || '',
      nrTelefone: cliente.nrTelefone || '',
      dsFoto: cliente.dsFoto || ''
    });

    if (cliente.dsFoto) {
      setArquivo({ dsArquivo: cliente.dsFoto, dsBase64: cliente.dsFoto });
    }

    if (cliente.uf) {
      municipioAPI
        .findMunicipioByUf(cliente.uf?.idUf)
        .then(municipio => {
          setMunicipioList(municipio.data);
        })
        .finally(() => {
          setKeyAutoComplete(Math.random());
        });
    }
  }

  /**
   * Mensagem retornada em casos de sucesso
   */
  function tratarMensagemSucesso() {
    Swal({
      showConfirmButton: true,
      showCancelButton: false,
      title: formatMessage('dadosPerfil.sucesso'),
      text: formatMessage('dadosPerfil.usuarioEditadoComSucesso'),
      icon: 'success'
    }).then(() => {
      history.push('/app');
    });
  }

  /**
   * Mensagem retornada em casoso de erro
   */
  function tratarMensagensErro(mensagemDetalhes: string) {
    Swal({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Ok',
      title: formatMessage('dadosPerfil.erroEditarUsuario'),
      text: mensagemDetalhes,
      icon: 'error'
    });
  }
}

export default DadosPerfil;
