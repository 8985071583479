import URL from 'root-resources/URL';
import Usuario from 'root-models/usuario';
import RootApi from '../root-api';
import Token from 'root-models/token';
import TokenController from './token-controller';
import { AxiosResponse } from 'axios';

/**
 * Classes para buscar informações referentes a autenticação do usuário
 *
 * @author Gabriela Farias
 * @author Bruno Eduardo
 */
export default class Authentication extends RootApi {
  /**
   * Parâmetros de autenticação para a request de login
   */
  private readonly loginParams: Record<string, any>;

  /**
   * Injeta as configurações da api e os parâmetros do login
   */
  public constructor() {
    super({ baseURL: URL.OAUTH });
    this.loginParams = { clientId: 'azagros', clientSecret: 'azagros-secret' };
  }

  /**
   * Certifica-se que o token do usuário está ativo e atualiza o token
   */
  public async refreshToken() {
    const token = TokenController.getToken();

    if (!token) {
      return Promise.reject();
    }

    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('client_id', this.loginParams.clientId);
    params.append('client_secret', this.loginParams.clientSecret);
    params.append('username', token.email);
    params.append('refresh_token', token.refresh_token);

    return this.api
      .post<Token>('authentication/login', params)
      .then((response) => {
        const newToken: Token = {
          ...response.data,
          email: token.email,
        };
        TokenController.setToken(newToken);
      })
      .catch((err) => {
        TokenController.setToken(null);
        return Promise.reject(err);
      });
  }

  /*
   * Realiza o login do usuário
   */
  public async login(mail: string, password: string) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', this.loginParams.clientId);
    params.append('client_secret', this.loginParams.clientSecret);
    params.append('username', mail);
    params.append('password', password);

    return this.api
      .post<Token>('authentication/login', params)
      .then((response) => {
        const newToken: Token = {
          ...response.data,
          email: mail,
        };
        TokenController.setToken(newToken);
      })
      .catch((err) => {
        TokenController.setToken(null);
        return Promise.reject(err);
      });
  }

  /*
   * Realiza o logout do usuário
   **/
  public static logout() {
    TokenController.setToken(null);
  }

  /**
   * Envia um email cada cadastrar um novo usuário
   */
  public async cadastrarUsuario(name: string, mail: string) {
    return this.api.post<Usuario>('/usuario', { dsEmail: mail, nmUsuario: name });
  }

  /**
   * Envia um email com um link para recuperar a senha do usuário
   */
  public async recuperarSenha(mail: string) {
    return this.api.put<Usuario>('/usuario/redefinir-senha', { dsEmail: mail });
  }

    /**
   * Reseta a senha do usuário
   */
  public async redefinirSenha(mail: string, senha: string, token: string) {
    return this.requestConfirmarResetarCadastro(mail, senha, token);
  }

  /**
   * Troca a senha do usuário
   */
  public async resetPassword(values: Usuario): Promise<AxiosResponse<Usuario>> {
    return this.api.put<Usuario>(`usuario/senha`, values);
  }

  /**
   * Confirma o cadastro do usuário
   */
  public async confirmarCadastro(mail: string, senha: string, token: string) {
    return this.requestConfirmarResetarCadastro(mail, senha, token);
  }

  /**
   * Request para alterar senha ou confirmar cadastro
   */
  private requestConfirmarResetarCadastro(mail: string, senha: string, token: string) {
    return this.api.post<Usuario>(`/usuario/confirmar-conta`, {
      dsEmail: mail,
      dsSenha: senha,
      dsToken: token,
    });
  }

  /**
   * Envia um email para o usuário alterar a senha
   */
  public async alterarSenha(values: Usuario) {
    return this.api.put<Usuario>('/usuario/alterarsenha', values);
  }
}
