import React, { useState, useEffect } from 'react';
import { useStyles } from './menu.styles';
import { Drawer, Collapse } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { AddCircle, Image, Add, FormatAlignLeft, ExpandMore, ExpandLess } from '@material-ui/icons/';
import { useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import DashboardLabelActions from 'root-states/actions/dashboard-label-actions';
import { Dispatch } from 'redux';
import Foto from 'root-models/foto';
import List from '@material-ui/core/List';
import MenuLateralItem from './menu-lateral-item';
import itensMenuLateral from './menu-itens-definitions';
import { useHistory } from 'react-router-dom';
import {
  useComponentDidMount,
  useComponentDidUnmount
} from '@kepha/sumora-react-components/lib/utils/hooks';
import Colecao from 'root-models/colecao';
import GrupoColecao from 'root-models/grupo-colecao';

const logoProjeto = require('../../assets/images/logo_projeto.svg');
const iconColecao = require('../../assets/images/icon_colecao.svg');

const colecaoListAux = [
  { idColecao: '0', nmColecao: '20/10/20 - Joystick', qtColecao: 236 },
  { idColecao: '1', nmColecao: 'Campanha de Black Friday de 2020', qtColecao: 24 },
  { idColecao: '2', nmColecao: '20/10/20', qtColecao: 2 }
];

const grupoColecaoListAux = [
  { idGrupoColecao: '0', nmGrupoColecao: 'Sem título', qtGrupoColecao: 236 },
  { idGrupoColecao: '1', nmGrupoColecao: 'Testes', qtGrupoColecao: 2.908 }
];

export type MenuLateralPropType = {
  onClickColections?: (type: number) => void;
};

/**
 * Representa o menu lateral do layout principal
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {MenuLateralPropType} props
 * @returns {JSX.Element}
 */
function MenuLateral(props: MenuLateralPropType): JSX.Element {
  const classes = useStyles(props);
  const { onClickColections } = props;
  const [showOptionsAdd, setShowOptionsAdd] = useState(false);
  const [showOptionsOrdenar, setShowOptionsOrdenar] = useState(false);
  const [iconShowMore, setIconShowMore] = useState(false);
  const [colecaoSelected, setColecaoSelected] = useState<string | undefined>();
  const [grupoColecaoList, setGrupoColecaoList] = useState<GrupoColecao[]>([]);
  const [colecaoList, setColecaoList] = useState<Colecao[]>([]);
  const history = useHistory();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const dashboardLabelActions = new DashboardLabelActions(useDispatch<Dispatch<DispatchAction>>());
  const listFotos: Foto[] = useSelector<MainStateType, Foto[]>(
    state => state.appLayoutReducer.listFotos
  );

  const eventClickOutsideCard = (event: any) => {
    if (!document.getElementById('div-click')?.contains(event.target)) {
      setShowOptionsAdd(false);
      setShowOptionsOrdenar(false);
    }
  };

  useComponentDidMount(() => {
    document.addEventListener('click', function (event) {
      eventClickOutsideCard(event);
    });
  });

  useComponentDidUnmount(() => {
    document.removeEventListener('click', function (event) {
      eventClickOutsideCard(event);
    });
  });

  useEffect(() => {
    setColecaoList(colecaoListAux);
    setGrupoColecaoList(grupoColecaoListAux);
  }, []);

  const drawerClasses = clsx(classes.menuDrawer, classes.menuDrawerOpen);

  return (
    <Drawer variant='permanent' className={drawerClasses} classes={{ paper: drawerClasses }}>
      <Link to='/app' className={classes.containerLogo}>
        <img src={logoProjeto} className={classes.logoProjeto} alt='logo' />
      </Link>

      {optionsMenuLateral()}
    </Drawer>
  );

  /**
   * Verifica qual menu mostrar de acordo com a rota
   */
  function optionsMenuLateral() {
    if (history.location.pathname.includes('/app/ajuda')) {
      return (
        <List className={clsx(classes.containerItens, classes.scrollMenu)}>
          {itensMenuLateral.itensMenuLateralAnalytics.map((item, i) => (
            <MenuLateralItem item={item} key={i} />
          ))}
        </List>
      );
    } else if (history.location.pathname.includes('/app/perfil')) {
      return (
        <List className={clsx(classes.containerItens, classes.scrollMenu)}>
          {itensMenuLateral.itensMenuLateralPerfil.map((item, i) => (
            <MenuLateralItem item={item} key={i} />
          ))}
        </List>
      );
    } else {
      return (
        <>
          <div className={classes.buttonNovaMidia}>
            <AddCircle />
            <div className={classes.novaMidia}>Nova Mídia</div>
          </div>

          <div className={classes.divisor} />

          <div
            onClick={() => {
              appLayoutActions.setMidiaToolbar(listFotos);
              setColecaoSelected(undefined);
              dashboardLabelActions.setTitleDashboard('Todas as Mídias');
            }}
            className={classes.buttonTodasMidias}
          >
            <Image />
            <div className={classes.todasMideas}>Todas as Mídias</div>
          </div>

          <div className={classes.divisor} />

          <div className={classes.buttonColecoes}>
            <div className={classes.colecoes}>
              <img src={iconColecao} className={classes.iconColecao} alt='logo' />
              Coleções
            </div>
            <div id='div-click' className={classes.iconsColecoes}>
              <Add
                onClick={() => {
                  setShowOptionsAdd(!showOptionsAdd);
                  setShowOptionsOrdenar(false);
                }}
                className={classes.iconGreen}
                style={{ color: showOptionsAdd ? '0BD690' : '#A5A5A5' }}
              />
              {showOptionsAdd && (
                <div className={classes.cardColecao}>
                  <p
                    className={classes.hoverFont}
                    onClick={() => {
                      onClickColections && onClickColections(0);
                      setShowOptionsAdd(false);
                    }}
                  >
                    Criar Coleção
                  </p>
                  <div style={{ height: 20 }} />
                  <p
                    className={classes.hoverFont}
                    onClick={() => {
                      onClickColections && onClickColections(1);
                      setShowOptionsAdd(false);
                    }}
                  >
                    Criar Grupo
                  </p>
                </div>
              )}
              <FormatAlignLeft
                onClick={() => {
                  setShowOptionsAdd(false);
                  setShowOptionsOrdenar(!showOptionsOrdenar);
                }}
                className={classes.iconBlue}
                style={{ color: showOptionsOrdenar ? '04A6FC' : '#A5A5A5' }}
              />
              {showOptionsOrdenar && (
                <div className={classes.cardOrdenacao}>
                  ORDENAR POR
                  <div className={classes.fontOrdenacao}>
                    <p className={classes.hoverFont}>A - Z nome</p>
                    <div style={{ height: 20 }} />
                    <p className={classes.hoverFont}>atualizada recentemente</p>
                    <div style={{ height: 20 }} />
                    <p className={classes.hoverFont}>mais antiga</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {colecaoList.map(item => (
            <div
              key={item.idColecao}
              onClick={() => {
                dashboardLabelActions.setTitleDashboard(item.nmColecao);
                setColecaoSelected(item.idColecao);
                appLayoutActions.setMidiaToolbar(
                  listFotos.filter(foto => foto.idColecao === item.idColecao)
                );
              }}
              style={{ backgroundColor: colecaoSelected === item.idColecao ? '#1C1C1C' : '' }}
              className={classes.listColecao}
            >
              <div className={classes.imageColecao}></div>
              <div
                className={classes.labelColecao}
                style={{ color: colecaoSelected === item.idColecao ? '#FFFFFF' : '' }}
              >
                <div className={classes.nomeItemColecao}>{item.nmColecao}</div>
                <div style={{ color: '#FFFFFF', fontSize: 11 }}>{item.qtColecao}</div>
              </div>
            </div>
          ))}

          <div className={classes.grupoColecoes}>
            <div>Grupo de Coleções</div>
            {!iconShowMore && (
              <ExpandMore
                onClick={() => setIconShowMore(!iconShowMore)}
                className={classes.iconGrupos}
              />
            )}
            {iconShowMore && (
              <ExpandLess
                onClick={() => setIconShowMore(!iconShowMore)}
                className={classes.iconGrupos}
              />
            )}
          </div>
          <Collapse in={!iconShowMore} timeout={300}>
            <div style={{ marginLeft: 20 }}>
              {grupoColecaoList.map(item => (
                <div
                  key={item.idGrupoColecao}
                  className={classes.listColecao}
                  style={{
                    backgroundColor:
                      colecaoSelected === `${item.idGrupoColecao}/grupoColecao` ? '#1C1C1C' : ''
                  }}
                  onClick={() => {
                    dashboardLabelActions.setTitleDashboard(item.nmGrupoColecao);
                    setColecaoSelected(`${item.idGrupoColecao}/grupoColecao`);
                  }}
                >
                  <div
                    style={{
                      color: colecaoSelected === `${item.idGrupoColecao}/grupoColecao` ? '#FFFFFF' : ''
                    }}
                    className={classes.imageColecao}
                  ></div>
                  <div className={classes.labelColecao}>
                    <div className={classes.nomeItemColecao}>{item.nmGrupoColecao}</div>
                    <div style={{ color: '#FFFFFF', fontSize: 11 }}>{item.qtGrupoColecao}</div>
                  </div>
                </div>
              ))}
            </div>
          </Collapse>
        </>
      );
    }
  }
}

export default MenuLateral;
