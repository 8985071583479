import Cliente from 'root-models/cliente';
import GenericApi from 'root-resources/generic-api';
import { AxiosResponse } from 'axios';

/**
 * API do Cliente
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @extends {RootApi}
 */
class ClienteAPI extends GenericApi<Cliente> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao cliente
   */
  public constructor() {
    super({ baseEndpoint: '/cliente' });
  }

  /**
   * Envia um email cada cadastrar um novo usuário
   */
  public async findClienteByEmail(email: string) : Promise<AxiosResponse<Cliente>> {
    return this.api.get<Cliente>('/email/' + email);
  }

  /**
   * Envia um email cada cadastrar um novo usuário
   */
  public async saveCliente(name: string, mail: string) {
    return this.api.post<Cliente>('', { dsEmail: mail, nmCliente: name });
  }
}

export default ClienteAPI;
