import React from 'react';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import ptBR from './../i18n/ptBR';
import enUS from './../i18n/enUS';
import esES from './../i18n/esES';
import { useStyles } from './alterar-senha.style';
import Admin from 'root-resources/oauth/admin';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@kepha/sumora-react-components/lib/textfield';
import { Grid } from '@material-ui/core';
import { useComponentDidMount } from '@kepha/sumora-react-components/lib/utils/hooks';
import Button from '@kepha/sumora-react-components/lib/button';
import AuthenticationApi from 'root-resources/oauth/authentication';
import ExceptionEnum from 'root-resources/exception-enum';
import Swal from 'root-components/swal/swal';
import { useHistory } from 'react-router-dom';

export type AlterarSenhaPropType = {};

/**
 * View AlterarSenha
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function AlterarSenha(props: AlterarSenhaPropType): JSX.Element {
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES });
  const authenticationApi = new AuthenticationApi();
  const classes = useStyles(props);
  const history = useHistory();

  const { values, errors, touched, handleBlur, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      dsSenha: '',
      dsNovaSenha: '',
      dsConfirmacaoSenha: '',
      dsEmail: '',
      nmUsuario: '',
      stUsuario: 0
    },
    validationSchema: Yup.object().shape({
      dsSenha: Yup.string().required(formatMessage('dadosPerfil.campoObrigatorio')),
      dsNovaSenha: Yup.string()
        .min(6, formatMessage('dadosPerfil.senhaMinino'))
        .required(formatMessage('dadosPerfil.campoObrigatorio')),
      dsConfirmacaoSenha: Yup.string()
        .min(6, formatMessage('dadosPerfil.senhaMinino'))
        .oneOf([Yup.ref('dsNovaSenha')], formatMessage('dadosPerfil.senhaIncompativel'))
        .required(formatMessage('dadosPerfil.campoObrigatorio'))
    }),
    onSubmit: submit,
    validateOnBlur: true,
    validateOnChange: false
  });

  useComponentDidMount(() => {
    const currentUser = Admin.getUsuarioLogado();
    if (!currentUser) {
      return;
    }
  });

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <div className={classes.title}>Alterar senha</div>
        </Grid>
        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='senha'
            placeholder={formatMessage('dadosPerfil.senhaAtual')}
            value={values.dsSenha}
            type={'password'}
            onChange={value => {
              setFieldValue('dsSenha', value.target.value);
            }}
            onBlur={handleBlur}
            error={touched.dsSenha && errors.dsSenha !== undefined}
            helperText={touched.dsSenha && errors.dsSenha !== '' ? errors.dsSenha : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={7} />

        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='novaSenha'
            placeholder={formatMessage('dadosPerfil.novaSenha')}
            value={values.dsNovaSenha}
            type={'password'}
            onChange={e => {
              setFieldValue('dsNovaSenha', e.target.value);
            }}
            onBlur={handleBlur}
            error={touched.dsNovaSenha && errors.dsNovaSenha !== undefined}
            helperText={touched.dsNovaSenha && errors.dsNovaSenha !== '' ? errors.dsNovaSenha : null}
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={7} />

        <Grid item xs={5} className={classes.textField}>
          <TextField
            id='dsConfirmacaoSenha'
            placeholder={formatMessage('dadosPerfil.confirmacaoNovaSenha')}
            value={values.dsConfirmacaoSenha}
            type={'password'}
            onChange={value => {
              setFieldValue('dsConfirmacaoSenha', value.target.value);
            }}
            onBlur={handleBlur}
            error={touched.dsConfirmacaoSenha && errors.dsConfirmacaoSenha !== undefined}
            helperText={
              touched.dsConfirmacaoSenha && errors.dsConfirmacaoSenha !== ''
                ? errors.dsConfirmacaoSenha
                : null
            }
            variant='outlined'
            InputProps={{
              className: classes.input
            }}
          />
        </Grid>

        <Grid item xs={7} />

        <Grid item xs={5}>
          <div className={classes.buttons}>
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              style={{ width: 100 }}
              onClick={() => {}}
            >
              Cancelar
            </Button>

            <div style={{ width: 35 }} />

            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              onClick={() => handleSubmit()}
            >
              Alterar senha
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  /**
   * Altera o nome e a senha do usuário
   */
  function submit() {
    values.dsEmail = Admin.getUsuarioLogado()?.dsEmail || '';
    values.nmUsuario = Admin.getUsuarioLogado()?.nmUsuario || '';
    values.stUsuario = Admin.getUsuarioLogado()?.stUsuario || 0;

    authenticationApi
      .resetPassword(values)
      .then(() => {
        tratarMensagemSucesso();
      })
      .catch(err => {
        if (err.response?.data.code === ExceptionEnum.SENHA_ATUAL_INCORRETA) {
          tratarMensagensErro(formatMessage('dadosPerfil.senhaAntigaNaoCoincide'));
          return;
        }

        tratarMensagensErro(formatMessage('dadosPerfil.ocorreuErroEditarSenha'));
      });
  }

  /**
   * Mensagem retornada em casos de sucesso
   */
  function tratarMensagemSucesso() {
    Swal({
      showConfirmButton: true,
      showCancelButton: false,
      title: formatMessage('dadosPerfil.senhaAlterada'),
      text: formatMessage('dadosPerfil.senhaAlteradaSucesso'),
      icon: 'success'
    }).then(() => {
      history.push('/app');
    });
  }

  /**
   * Mensagem retornada em casoso de erro
   */
  function tratarMensagensErro(mensagemDetalhes: string) {
    Swal({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Ok',
      title: formatMessage('dadosPerfil.erroEditarSenha'),
      text: mensagemDetalhes,
      icon: 'error'
    });
  }
}

export default AlterarSenha;
