import Usuario from 'root-models/usuario';
import RootApi from 'root-resources/root-api';
import { deepClone } from '@kepha/sumora-react-components/lib';
import URL from 'root-resources/URL';

/**
 * Classe para buscar informaçoes referentes ao usuário logado
 *
 * @author Gabriela Farias
 * @author Bruno Eduardo
 */
export default class Admin extends RootApi {
  public constructor() {
    super({ baseURL: URL.OAUTH });
  }

  /**
   * Memória dos dados do usuário logado
   */
  private static usuarioLogado: Usuario | null = null;

  /*
   * Valida, busca e atualiza os dados do usuário
   */
  public async findPerfil() {
    if (Admin.usuarioLogado !== null) {
      return Promise.resolve(Admin.usuarioLogado);
    }

    return this.api
      .get<Usuario>('/usuario/token')
      .then((response) => {
        this.setDadosUsuario(response.data);
        return response.data;
      })
      .catch((err) => {
        this.clearDadosUsuario();
        return Promise.reject(err);
      });
  }

  /**
   * Edita as informaçoes do perfil
   */
  public async updateUsuario(values: Usuario) {
    return this.api.put<Usuario>('/perfil', values).then((res) => {
      this.setDadosUsuario(res.data);
      return res;
    });
  }

  /**
   * @returns Dados do usuário logado
   */
  public static getUsuarioLogado(): Usuario | null {
    return deepClone(Admin.usuarioLogado);
  }

  /*
   * Limpa os dados salvos do usuário
   */
  private clearDadosUsuario() {
    Admin.usuarioLogado = null;
  }

  /**
   * Setta os dados do usuário logado
   *
   * @param {(Usuario | null)} _dadosUsuario
   */
  private setDadosUsuario(_dadosUsuario: Usuario | null) {
    Admin.usuarioLogado = deepClone(_dadosUsuario);
  }
}
