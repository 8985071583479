import { makeStyles } from '@material-ui/core';

export const menuLateralMaxWidth = 270;
export const menuLateralMinWidth = 50;

const useStyles = makeStyles(theme => ({
  menuDrawer: {
    backgroundColor: '#2D2D2D',
    width: menuLateralMaxWidth,
    height: '100vh',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderRightWidth: 0
  },
  menuDrawerOpen: {
    width: menuLateralMaxWidth,
    overflowX: 'hidden',
  },
  containerLogo: {
    height: 50,
    display: 'flex',
    backgroundColor: '#000000'
  },
  logoProjeto: {
    height: 30,
    width: 80,
    marginLeft: 20,
    marginTop: 10
  },
  novaMidia: {
    fontSize: 18,
    marginLeft: 10,
    height: 21
  },
  todasMideas: {
    fontSize: 15,
    marginLeft: 10
  },
  colecoes: {
    fontSize: 15,
    marginLeft: 15
  },
  buttonTodasMidias: {
    marginTop: 10,
    margin: 10,
    padding: 10,
    cursor: 'pointer',
    height: 15,
    color: '#A5A5A5',
    backgroundColor: '#1C1C1C',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  buttonNovaMidia: {
    marginBottom: 10,
    marginTop: 10,
    margin: 5,
    padding: 15,
    cursor: 'pointer',
    height: 13,
    color: '#A5A5A5',
    backgroundColor: '#2D2D2D',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#04A6FC',
      color: '#FFFFFF'
    }
  },
  buttonColecoes: {
    marginTop: 15,
    cursor: 'pointer',
    height: 13,
    color: '#A5A5A5',
    backgroundColor: '#2D2D2D',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconsColecoes: {
    width: 65,
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 15
  },
  iconGreen: {
    '&:hover': {
      color: '#0BD690 !important'
    }
  },
  iconBlue: {
    '&:hover': {
      color: '#04A6FC !important'
    }
  },
  hoverFont: {
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  divisor: {
    backgroundColor: '#000000',
    width: '100%',
    height: 2,
  },
  cardColecao: {
    position: 'fixed',
    background: '#000000',
    zIndex: 1000,
    marginTop: 25,
    marginLeft: 80,
    height: 50,
    width: 100,
    padding: 15
  },
  cardOrdenacao: {
    fontSize: 11,
    position: 'fixed',
    background: '#000000',
    zIndex: 1000,
    marginTop: 25,
    marginLeft: 215,
    height: 120,
    width: 170,
    padding: 15
  },
  fontOrdenacao: {
    fontSize: 14,
    marginTop: 20,
    marginLeft: 15
  },
  imageColecao: {
    height: 32,
    width: 35,
    backgroundColor: '#A5A5A5',
    marginLeft: 8,
    borderRadius: 3
  },
  listColecao: {
    cursor: 'pointer',
    height: 35,
    marginTop: 20,
    marginLeft: 9,
    marginRight: 9,
    color: '#A5A5A5',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#1C1C1C',
    }
  },
  labelColecao: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 60px)',
    marginLeft: 8,
    marginRight: 8,
    alignItems: 'center',
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  nomeItemColecao: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 13
  },
  grupoColecoes: {
    margin: '30px 15px 0 15px',
    color: '#A5A5A5',
    backgroundColor: '#1C1C1C',
    borderRadius: 5,
    height: 15,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 5px 10px 5px',
    justifyContent: 'space-between',
    paddingRight: 5,
    fontSize: 15
  },
  iconGrupos: {
    color: '#04A6FC',
    cursor: 'pointer',
  },
  iconColecao: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  containerItens: {
    paddingTop: 0,
    paddingBottom: 0,
    color: 'white',
    overflowX: 'hidden',
  },
  scrollMenu: {
    overflowY: 'auto',
  },
  containerItem: {
    paddingLeft: 10,
    height: 50,
    borderBottom: '2px solid #0F0F0F',
    color: '#A5A5A5',
    '&:hover': {
      color: '#F4F4F4',
      backgroundColor: '#242424'
    }
  },
  itemIcon: {
    width: 25,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  label: {
    fontSize: '18px !important',
  }
}));

export { useStyles };
