import { combineReducers } from 'redux';
import appLayoutReducer, { AppLayoutStateType } from 'root-states/reducers/app-layout-reducer';
import cardFiltersReducer, { CardFiltersStateType } from 'root-states/reducers/card-filters-reducer';
import dashboardLabelActions, { DashboardTitleStateType } from 'root-states/reducers/dashboard-title-reducer';

/**
 * Modelo do State do Redux
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @interface ReduxStateType
 */
export interface MainStateType {
  appLayoutReducer: AppLayoutStateType;
  cardFiltersReducer: CardFiltersStateType;
  dashboardLabelActions: DashboardTitleStateType;
}

const Reducers = combineReducers({ appLayoutReducer, cardFiltersReducer, dashboardLabelActions });

export default Reducers;
