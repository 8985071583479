import React from 'react';
import { useStyles } from './planos-assinaturas.style';
import { Grid } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons/';
import Button from '@kepha/sumora-react-components/lib/button';

export type PlanosAssinaturasPropType = {};

const listLancamentos = [
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Plano Lite - Assinatura',
    vlPlano: 'R$ 29,90'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Cadastro de novo Projeto',
    vlPlano: 'R$ 50,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Reconhecimentos Extras',
    vlPlano: 'R$27,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Plano Lite - Assinatura',
    vlPlano: 'R$ 29,90'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Cadastro de novo Projeto',
    vlPlano: 'R$ 50,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Reconhecimentos Extras',
    vlPlano: 'R$27,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Plano Lite - Assinatura',
    vlPlano: 'R$ 29,90'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Cadastro de novo Projeto',
    vlPlano: 'R$ 50,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Reconhecimentos Extras',
    vlPlano: 'R$27,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Plano Lite - Assinatura',
    vlPlano: 'R$ 29,90'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Cadastro de novo Projeto',
    vlPlano: 'R$ 50,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Reconhecimentos Extras',
    vlPlano: 'R$27,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Plano Lite - Assinatura',
    vlPlano: 'R$ 29,90'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Cadastro de novo Projeto',
    vlPlano: 'R$ 50,00'
  },
  {
    date: '19 de nov. de 2020',
    nmPlano: 'Reconhecimentos Extras',
    vlPlano: 'R$27,00'
  }
];

/**
 * View de planos e assinaturas
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function PlanosAssinaturas(props: PlanosAssinaturasPropType): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background} style={{ height: 240 }}>
        <Grid container>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.title}>
              Plano Atual - <p className={classes.titleListe}>Lite</p>
            </div>
          </Grid>
          <Grid item xs={5} className={classes.gridItem}>
            <div className={classes.subtitle}>Plano anual, cobranças mensais</div>
          </Grid>
          <Grid item xs={7} className={classes.gridItem}>
            <div className={classes.subtitle}>
              O próximo pagamento está programado para 19 de dez. de 2020.
            </div>
          </Grid>

          <Grid item xs={5}>
            <Button
              style={{ marginTop: 50 }}
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              onClick={() => {}}
            >
              Fazer upgrade
            </Button>
          </Grid>

          <Grid item xs={7} className={classes.cardPagamentos}>
            <div className={classes.cardCartao}>
              Visa com final ****3417
              <KeyboardArrowDown className={classes.iconRow} />
            </div>
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              onClick={() => {}}
            >
              Fazer upgrade
            </Button>
          </Grid>

          <Grid item xs={5} />

          <Grid item xs={7} className={classes.excluirCartao}>
            Excluir este cartão
          </Grid>
        </Grid>
      </div>
      <div className={classes.background}>
        <div className={classes.title} style={{ marginLeft: 70 }}>
          Últimos lançamentos
        </div>
        <div className={classes.divisor}></div>
        <div className={classes.lancamentos}>
          {listLancamentos.map(lancamento => (
            <Grid container className={classes.labelLancamentos}>
              <Grid item xs={2} className={classes.labelDate}>
                {lancamento.date}
              </Grid>
              <Grid item xs={6} className={classes.labelDescricao}>
                {lancamento.nmPlano}
              </Grid>
              <Grid item xs={2} className={classes.labelValor}>
                {lancamento.vlPlano}
              </Grid>
              <Grid item xs={2} className={classes.labelVisualizar}>
                visualizar
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
    </>
  );
}

export default PlanosAssinaturas;
