export default {
  confirmarCadastro: {
    campoObrigatorio: 'Campo obligatorio',
    emailInvalido: 'Email inválido',
    minimoSenha: 'La contraseña debe tener al menos 6 caracteres',
    senha: 'Contraseña *',
    senhasIncompativeis: 'Contraseñas incompatibles',
    confirmarSenha: 'Confirmación de contraseña',
    codigo: 'Código',
    sucesso: 'Éxito',
    codigoInvalido: 'Código no válido',
    falhaConectar: 'Se produjo un error al recuperar la contraseña',
    confirmarCadastro: 'Confirmar registro',
    cadastroJaConfirmado: 'Registro ya confirmado',
    cadastroConfirmado: 'El registro confirmado con éxito',
  },
};
