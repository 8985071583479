import RootDispatcher from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import DashboardLableActionTypes from 'root-states/actiontypes/dashboard-label-action-types';

/**
 * Actions para o Reducer do título da dashboard
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @class DashboardLabelActions
 * @extends {RootDispatcher}
 */
class DashboardLabelActions extends RootDispatcher {
  /**
   * Construtor padrão que injeta o dispatch
   *
   * @param {Dispatch<DispatchAction>} dispatch
   */
  public constructor(dispatch: Dispatch<DispatchAction>) {
    super(dispatch);
  }


  /**
   * Setta o título da dashboard
   */
  public setTitleDashboard(label?: string) {
    this.dispatch({ type: DashboardLableActionTypes.TITLE_TOOLBAR, payload: label });
  }
}

export default DashboardLabelActions;
