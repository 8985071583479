import Colecao from 'root-models/colecao';
import GenericApi from 'root-resources/generic-api';

/**
 * API do coleção
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @extends {RootApi}
 */
class ColecaoAPI extends GenericApi<Colecao> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Uf
   */
  public constructor() {
    super({ baseEndpoint: '/colecao' });
  }
}

export default ColecaoAPI;
