import { makeStyles } from '@material-ui/core';

const backgroundImage = require('root-images/background_image.png');

/**
 * Estilos da view de login
 **/
const useStyles = makeStyles((theme) => ({
  background: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    backgroundColor: '#242424',
    transition: ' height 220ms',
    width: 450,
    borderRadius: 0
  },
  grid: {
    marginTop: 25,
  },
  esqueceuSenha: {
    paddingRight: 12,
    paddingLeft: 12,
    display: 'flex',
    width: '100%',
    height: 60,
    marginBottom: 20,
    fontSize: 14,
    color: '#26B767',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
  },
  label: {
    textDecoration: 'none',
    color: '#A5A5A5',
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    fontSize: 13,
    '&:hover': {
      color: '#FFFFFF',
    }
  },
  button: {
    justifyContent: 'center',
    display: 'flex',
  },
  labelButton: {
    width: 70,
    height: 35,
    color: '#A5A5A5',
    backgroundColor: '#000000',
    border: '1px solid #A5A5A5',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 11,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: '#0BD690',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    }
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  textField: {
    height: 55,
    padding: '0px 12px 0px 12px !important'
  },
  textPassword: {
    overflow: 'hidden',
    transition: 'max-height 220ms',
    width: '100%',
  },
  title: {
    color: '#FFFFFF',
    fontSize: 16,
    height: 35,
    borderBottom: '2px solid rgb(119, 119, 119, 0.6)',
    display: 'flex',
    alignItems: 'flex-start',
  },
  input: {
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 1) !important',
    height: 35
  },
  cadastrar: {
    fontSize: 16,
    borderTop: '2px solid rgb(119, 119, 119, 0.6)',
    height: 40,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  clickCadastrar: {
    cursor: 'pointer',
    display: 'flex',
  }
}));

export { useStyles };
