import { makeStyles } from '@material-ui/core';

/**
 * Estilos do componente de modal pra grupo e coleção
 */
const useStyles = makeStyles({
  cardAll: {
    padding: 30,
    alignItems: 'center',
    display: 'flex',
    justifyContent:  'space-between',
    flexDirection: 'column',
    height: 'calc(100% - 60px)'
  },
  criarNova: {
    fontSize: 18,
    color: '#F4F4F4',
    borderBottom: '1px solid #707070',
    width: '100%',
    paddingBottom: 20
  },
  input: {
    color: '#FFFFFF !important',
    backgroundColor: '#1C1C1C !important',
    height: 35,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  labelButton: {
    width: 60,
    height: 35,
    color: '#A5A5A5',
    backgroundColor: '#000000',
    border: '1px solid #A5A5A5',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 11,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: '#04A6FC',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    }
  },
  labelObs: {
    fontSize: 13,
    height: 25,
    width: '100%'
  },
  cardTextField: {
    height: 50,
    width: '100%',
    marginTop: 10
  }
});

export { useStyles };
