import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#04A6FC',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#E6F8FD',
    },
    error: {
      main: '#f33a30',
    },
    background: {
      default: 'linear-gradient(#46A770, #00863B)',
    },
    text: {
      primary: 'rgb(0, 0, 0, 0.7)',
      secondary: 'rgb(0, 0, 0, 0.6)',
    },
  },
});

export { theme };
