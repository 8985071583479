import { makeStyles } from '@material-ui/core';

/**
 * Estilos da view de configuração do app
 **/
const useStyles = makeStyles({
  background: {
    backgroundColor: '#0F0F0F',
    display: 'flex',
    height: '47vh'
  },
  card: {
    backgroundColor: '#1C1C1C',
    width: '50%',
    margin: '4px 4px 4px 4px',
    padding: 30
  },
  title: {
    color: '#A5A5A5',
    fontSize: 18
  },
  labelButton: {
    width: 150,
    height: 35,
    color: '#A5A5A5',
    backgroundColor: '#000000',
    border: '1px solid #A5A5A5',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 11,
    fontWeight: 300,
    '&:hover': {
      backgroundColor: '#04A6FC',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    }
  },
  image: {
    maxWidth: '50vh',
    maxHeight: '25vh'
  },
  buttons: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  backgroundColors: {
    backgroundColor: '#1C1C1C !important',
    boxShadow: 'none !important',
    width: 'calc(100% - 130px) !important',
    padding: 30,
  },
  cardColors: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardFileName: {
    display: 'flex',
    color: '#F4F4F4',
    fontSize: 13,
    width: 'calc(100% - 100px)',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

export { useStyles };
