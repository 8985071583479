export default {
  recuperarSenha: {
    campoObrigatorio: 'Campo obligatorio',
    emailInvalido: 'Email inválido',
    minimoSenha: 'La contraseña debe tener al menos 6 caracteres',
    senha: 'Contraseña *',
    retornarLogin: 'Volver al inicio',
    confirmar: 'Confirmación de contraseña',
    senhasIncompativeis: 'Contraseñas incompatibles',
    confirmarSenha: 'Confirmación de contraseña',
    codigo: 'Código',
    sucesso: 'Éxito',
    reencaminhar: 'Usted será reenviado para realizar su login',
    falhaRecuperarSenha: 'Error al recuperar contraseña',
    codigoExpirado: 'Código caducado',
    codigoInvalido: 'Código no válido',
    falhaConectar: 'Se produjo un error al recuperar la contraseña',
  },
};
