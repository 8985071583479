import { makeStyles } from '@material-ui/core';

export const toolbarHeight = 50;

const useStyles = makeStyles((theme) => ({
  container: {
    height: toolbarHeight,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: '#2D2D2D'
  },
  containerLeftItem: {
    display: 'flex',
    alignItems: 'center',
  },
  containerRightItem: {
    fontSize: 25,
    display: 'flex',
    alignItems: 'center',
    width: 110,
    justifyContent: 'space-between'
  },
  buttonMenu: {
    borderRadius: '50%',
    width: 35,
    height: 35,
    minWidth: 'auto',
    backgroundColor: 'white',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  titleToolbar: {
    marginLeft: 15,
    fontSize: '1.71rem',
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  input: {
    color: '#A5A5A5 !important',
    backgroundColor: '#1C1C1C !important',
    height: 30,
    width: 400
  },
  iconBarras: {
    cursor: 'pointer',
    height: 25,
    width: 25,
    color: '#707070',
    '&:hover': {
      color: '#04A6FC',
    },
  },
  iconSair: {
    cursor: 'pointer',
    height: 25,
    width: 25,
    color: '#707070',
    '&:hover': {
      color: '#00A8F9',
    },
  },
  iconProfile: {
    cursor: 'pointer',
    height: 25,
    width: 25,
    color: '#707070',
    '&:hover': {
      color: '#00A8F9 !important',
    },
  },
  iconDuvidas: {
    cursor: 'pointer',
    color: '#777777',
    border: '2px solid #777777',
    fontSize: 16,
    borderRadius: 100,
    width: 18,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 400,
    height: 18,
    '&:hover': {
      color: '#00A8F9',
      border: '2px solid #00A8F9',
    },
  },
  cardPerfil: {
    position: 'absolute',
    height: 100,
    width: 115,
    zIndex: 1000,
    fontSize: 12,
    backgroundColor: '#242424',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 180,
    marginLeft: -35,
    color: '#A5A5A5',
    padding: 15
  },
  itemCardPerfil: {
    cursor: 'pointer',
    marginBottom: 15,
    '&:hover': {
      color: '#FFFFFF',
    },
  },
}));

export { useStyles };
