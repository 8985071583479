import React, { useState } from 'react';
import { useStyles } from './toolbar.styles';
import { TextField, InputAdornment } from '@material-ui/core/';
import { Search, SignalCellularAlt } from '@material-ui/icons';
import PerfilIcon from '@material-ui/icons/Person';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import Authentication from 'root-resources/oauth/authentication';
import { useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { Dispatch } from 'redux';
import Foto from 'root-models/foto';
import {
  useComponentDidMount,
  useComponentDidUnmount
} from '@kepha/sumora-react-components/lib/utils/hooks';

export type MainToolbarPropType = {};

const listItensCardPerfil = [
  {
    key: 0,
    name: 'seus dados',
    rota: 'dados'
  },
  {
    key: 1,
    name: 'planos e assinaturas',
    rota: 'planos'
  },
  {
    key: 2,
    name: 'APP - Configuração',
    rota: 'configuracao'
  }
];

/**
 * Representa o header/toolbar do layout principal
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {MainToolbarPropType} props
 */
function MainToolbar(props: MainToolbarPropType): JSX.Element {
  const classes = useStyles(props);
  const [searchMidia, setSearchMidia] = useState('');
  const [perfilOptionsOpen, setPerfilOptionsOpen] = useState(false);
  const history = useHistory();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const listFotos: Foto[] = useSelector<MainStateType, Foto[]>(
    state => state.appLayoutReducer.listFotos
  );

  const eventClickOutsideCard = (event: any) => {
    if (!document.getElementById('div-icon')?.contains(event.target)) {
      setPerfilOptionsOpen(false);
    }
  };

  useComponentDidMount(() => {
    document.addEventListener('click', function (event) {
      eventClickOutsideCard(event);
    });
  });

  useComponentDidUnmount(() => {
    document.removeEventListener('click', function (event) {
      eventClickOutsideCard(event);
    });
  });

  return (
    <div className={classes.container}>
      <div />
      <TextField
        value={searchMidia}
        placeholder='Pesquisar Mídias'
        variant='outlined'
        onChange={e => {
          setSearchMidia(e.target.value);
          let valueNormalize = normalizeString(e.target.value);

          appLayoutActions.setMidiaToolbar(
            listFotos.filter(item => normalizeString(item.nmFoto).includes(valueNormalize))
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='end'>
              <Search style={{ marginRight: 15, color: '#A5A5A5' }} />
            </InputAdornment>
          ),
          className: classes.input
        }}
      />
      <div className={classes.containerRightItem}>
        <SignalCellularAlt
          fontSize='inherit'
          onClick={() => history.push('/app/analytics')}
          className={classes.iconBarras}
        />

        <div className={classes.iconDuvidas} onClick={() => history.push('/app/ajuda')}>
          {' '}
          ?{' '}
        </div>

        <PerfilIcon
          id='div-icon'
          onClick={() => setPerfilOptionsOpen(!perfilOptionsOpen)}
          style={{ color: perfilOptionsOpen ? '00A8F9' : '#707070' }}
          fontSize='inherit'
          className={classes.iconProfile}
        />

        {perfilOptionsOpen && (
          <div className={classes.cardPerfil}>
            {listItensCardPerfil.map(itemList => (
              <div
                key={itemList.key}
                className={classes.itemCardPerfil}
                onClick={() => {
                  history.push('/app/perfil/' + itemList.rota);
                  setPerfilOptionsOpen(false);
                }}
              >
                {itemList.name}
              </div>
            ))}
            <div
              className={classes.itemCardPerfil}
              onClick={() => {
                Authentication.logout();
                history.push('/login');
              }}
            >
              sair
            </div>
          </div>
        )}
      </div>
    </div>
  );

  /**
   * Retira caracteres especiais da string e ajusta em fonte baixa
   *
   * @param value - valor a ser normalizado
   */
  function normalizeString(value: string) {
    return value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
}

export default MainToolbar;
