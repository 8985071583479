import GenericApi from 'root-resources/generic-api';
import GrupoColecao from 'root-models/grupo-colecao';

/**
 * API do grupo de coleção
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @extends {RootApi}
 */
class GrupoColecaoAPI extends GenericApi<GrupoColecao> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Uf
   */
  public constructor() {
    super({ baseEndpoint: '/grupo-colecao' });
  }
}

export default GrupoColecaoAPI;
