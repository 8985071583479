import { makeStyles } from '@material-ui/core';
import { menuLateralMaxWidth, menuLateralMinWidth } from '../components/menulateral/menu.styles';
import { toolbarHeight } from 'root-components/maintoolbar/toolbar.styles';

/**
 * Estilos do componente principal da aplicação
 */
const useStyles = makeStyles({
  main: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: '#0F0F0F'
  },
  containerToolbarAndRoutes: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: `calc(100vw - ${menuLateralMinWidth}px)`,
    minWidth: `calc(100vw - ${menuLateralMaxWidth}px)`,
    width: '100%',
  },
  contentContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: `calc(100vh - ${toolbarHeight})`,
  },
});

export { useStyles };
