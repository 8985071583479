export default {
  dadosPerfil: {
    nomeCompleto: 'Nome Completo do Profissional',
    email: 'emaildoprofissional@arpaly.com.br',
    nomeEmpresa: 'Nome da Empresa ou Nome Fantasia',
    cpf: 'CPF',
    rg: 'RG',
    endereco: 'Endereço',
    bairro: 'Bairro',
    cidade: 'Cidade',
    estado: 'Estado',
    cep: 'CEP',
    campoObrigatorio: 'Campo Obrigatório',
    cpfInvalido: 'CPF inválido',
    cepInvalido: 'CEP inválido',
    telefone: 'Telefone',
    telefoneInvalido: 'Telefone inválido',
    senhaAtual: 'Senha atual',
    novaSenha: 'Nova senha',
    confirmacaoNovaSenha: 'Confirmar nova senha',
    dadosPessoais: 'Dados pessoais',
    senhaMinino: 'A senha deve ter no mínimo 6 caracteres',
    senhaIncompativel: 'Senhas incompatíveis',
    senhaIncorreta: 'Senha incorreta',
    senhaAlterada: 'Senha alterada',
    senhaAlteradaSucesso: 'Senha alterada com sucesso',
    erroEditarSenha: 'Falha ao alterar senha',
    ocorreuErroEditarSenha: 'Ocorreu um erro ao editar a senha',
    senhaAntigaNaoCoincide: 'Senha antiga não correspondente',
    usuarioEditadoComSucesso: 'Usuário editado com sucesso',
    sucesso: 'Sucesso',
    erroEditarUsuario: 'Erro ao editar usuário',
    ocorreuErroEditarUsuario: 'Ocorreu um erro ao editar usuário',
    ocorreuUmErroBuscarUsuario: 'Ocorreu um erro ao buscar usuário'
  },
};
