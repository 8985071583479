import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { Dispatch } from 'redux';
import Foto from 'root-models/foto';
import PalavraChave from 'root-models/palavra-chave';
import FilterModel from '@kepha/sumora-react-components/lib/cardfilters/models/filter';
import { useComponentDidMount } from '@kepha/sumora-react-components/lib/utils/hooks';
import { useStyles } from './dashboard.styles';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import ptBR from './i18n/ptBR';
import esES from './i18n/esES';
import enUS from './i18n/enUS';
import Swal from 'root-components/swal/swal';
import Modal from 'root-components/modal/modal';
import CardOrdenacao from 'root-components/cardordenar/card-ordenar';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { Image, Notes, Close, AddCircle } from '@material-ui/icons/';
import { TextField, Grid, Chip } from '@material-ui/core';
import CardFoto from 'root-components/cardfoto/card-foto';
import { useHistory } from 'react-router-dom';

const iconEtiqueta = require('../../assets/images/icon_etiqueta.svg');
const iconVazio = require('../../assets/images/icon_vazio.svg');

/** MÉTODOS AUXILIARES PARA SIMULAR UMA API */

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const palavrasChavesList = [
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave',
  'Palavra-chave'
];

const listChips = [
  { idPalavraChave: '0', nmPalavraChave: 'Ribeirão Preto' },
  { idPalavraChave: '1', nmPalavraChave: 'Formandos 2020' },
  { idPalavraChave: '2', nmPalavraChave: 'Medicina USP' },
  { idPalavraChave: '3', nmPalavraChave: 'Formatura Eng. Software' },
  { idPalavraChave: '4', nmPalavraChave: 'Gabriela Farias' }
];

const nomes = [
  'Roberto',
  'Maria',
  'José',
  'Mauricio',
  'Larissa',
  'Gabriel',
  'Bruno',
  'Gabriela',
  'João',
  'Murilo'
];

function getFakeData() {
  const dataAux: Foto[] = [];

  const qtdDados = getRandomInt(50, 100);
  for (let i = 0; i < qtdDados; i++) {
    const nome = nomes[getRandomInt(0, nomes.length - 1)];
    const dateCriacao = new Date();
    const dateExpiracao = new Date();

    dateCriacao.setDate(getRandomInt(0, 30));
    dateExpiracao.setDate(getRandomInt(0, 30));

    dataAux.push({
      idFoto: i.toString(),
      nmFoto: nome,
      tpFoto: getRandomInt(0, 2),
      dtCriacao: dateCriacao,
      dtExpiracao: dateExpiracao,
      idColecao: getRandomInt(0, 2).toString(),
      palavraChaveList: listChips
    });
  }

  return dataAux;
}

async function apiRequestSimulation(): Promise<{ data: Foto[]; status: number }> {
  return new Promise((resolve, reject) => {
    if (getRandomInt(0, 100) === 22) {
      reject('Ocorreu um erro');
    } else {
      setTimeout(() => {
        resolve({ data: getFakeData(), status: 200 });
      }, 0);
    }
  });
}
/** MÉTODOS AUXILIARES PARA SIMULAR UMA API */

export type DashboardPropType = {};

/**
 * Tela de listagem de fotos
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {DashboardPropType} props
 */
function Dashboard(props: DashboardPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const history = useHistory();
  const [showOptionsOrdenar, setShowOptionsOrdenar] = useState(false);
  const [palavraChaveSelected, setPalavraChaveSelected] = useState<PalavraChave[]>([]);
  const [itemSelecionado, setItemSelecionado] = useState<Foto | null>(null);
  const [showOptionsCard, setShowOptionsCard] = useState(false);
  const [itemShowOptions, setItemShowOptions] = useState<Foto | null>(null);
  const [statusModal, setStatusModal] = useState(false);
  const [indexSelectOrder, setIndexSelectOrder] = useState(0);
  const [showPalavrasChave, setShowPalavrasChave] = useState(false);
  const [textPalavraChave, setTextPalavraChave] = useState('');
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'es-ES': esES, 'en-US': enUS });

  const listFotos: Foto[] = useSelector<MainStateType, Foto[]>(state => {
    return state.appLayoutReducer.listFotosFiltered;
  });

  const titleToolbar: string = useSelector<MainStateType, string>(state => {
    return state.dashboardLabelActions.label;
  });

  useComponentDidMount(() => {
    if (history.location.pathname === '/app') {
      loadData();
    }
  });

  return (
    <div className={classes.rootListagem}>
      {showPalavrasChave && (
        <div className={classes.palavraChaveCard} onClick={() => setShowPalavrasChave(false)}>
          <div className={classes.palavraChave}>
            Palavras-chave
            <div style={{ height: 20 }} />
            {palavrasChavesList.map(palavra => (
              <div key={Math.random()} className={classes.itemPalavraChave}>
                {palavra}
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal
        open={statusModal}
        clickInsideModal={() => setStatusModal(!statusModal)}
        conteudoModal={
          <div className={classes.conteudoModal}>
            Palavras-chave
            <Grid style={{ marginTop: 15 }} container>
              <Grid item xs={12} style={{ marginBottom: 15 }}>
                <TextField
                  value={textPalavraChave}
                  placeholder='Nome da Mídia'
                  variant='standard'
                  onChange={e => setTextPalavraChave(e.target.value)}
                  InputProps={{
                    className: classes.input
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {palavraChaveSelected.map(item => (
                  <Chip
                    key={item.idPalavraChave}
                    className={classes.chip}
                    label={item.nmPalavraChave}
                    onClick={() => {}}
                    onDelete={() => {}}
                    deleteIcon={<Close className={classes.iconChip} />}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        }
      />

      <div
        className={classes.containerOptions}
        style={{ height: itemSelecionado ? 36 : 0, opacity: itemSelecionado ? 1 : 0 }}
      >
        <div style={{ borderLeft: '2px solid #0F0F0F' }} className={classes.optionsItem}>
          Inativar
        </div>
        <div className={classes.optionsItem}>Ativar</div>
        <div className={classes.optionsItem}>Mover</div>
        <div className={classes.optionsItem}>Excluir</div>
        <div style={{ borderRight: '2px solid #0F0F0F' }} className={classes.optionsItem}>
          Analytics
        </div>
      </div>

      <div className={classes.cardToolbar} style={{ marginTop: itemSelecionado ? 36 : 0 }}>
        <div className={classes.todasMideas}>
          <Image />
          <div style={{ width: 15 }} />
          {titleToolbar}
          <div style={{ width: 15 }} />
          <Notes onClick={() => setShowOptionsOrdenar(!showOptionsOrdenar)} className={classes.notes} />
          {showOptionsOrdenar && (
            <CardOrdenacao
              indexSelect={indexSelectOrder}
              clickClose={(value: number) => {
                setShowOptionsOrdenar(false);
                setIndexSelectOrder(value);
                setItemShowOptions(null);
                setShowOptionsCard(false);
                setItemSelecionado(null);
              }}
            />
          )}
        </div>
        <img
          src={iconEtiqueta}
          onClick={() => setShowPalavrasChave(true)}
          className={classes.iconEtiqueta}
          alt='filtros'
        />
      </div>
      <div className={classes.containerGrid} style={{ marginTop: itemSelecionado ? 86 : 50 }}>
        {listFotos.length > 0 ? (
          listFotos.map(item => (
            <CardFoto
              key={item.idFoto}
              itemSelecionado={itemSelecionado}
              showOptionsCard={showOptionsCard}
              itemShowOptions={itemShowOptions}
              clickFoto={() => clickFoto(item)}
              clickIconMore={() => clickIconMore(item)}
              changeStatusModal={() => {
                setStatusModal(true);
                setPalavraChaveSelected(item.palavraChaveList);
              }}
              item={item}
            />
          ))
        ) : (
          <div className={classes.cardVazio}>
            {formatMessage(
              history.location.pathname.includes('colecao')
                ? 'usuario.estaColecaoVazia'
                : 'usuario.esteGrupoVazio'
            )}
            <div style={{ height: 25 }} />
            <img src={iconVazio} alt='icon' />
            <div className={classes.cardObs}>
              {' '}
              {formatMessage(
                history.location.pathname.includes('colecao')
                  ? 'usuario.crieUmaMidia'
                  : 'usuario.crieUmaColecao'
              )}
            </div>
            <div className={classes.buttonNovaMidia}>
              <AddCircle />
              <div className={classes.novaMidia} onClick={() => {}}>
                {formatMessage(
                  history.location.pathname.includes('colecao')
                    ? 'usuario.novaMidia'
                    : 'usuario.novaColecao'
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  /**
   * Ação ao clicar no icone mais da foto
   */
  function clickIconMore(item: Foto) {
    setShowOptionsCard(!showOptionsCard);
    if (itemShowOptions && itemShowOptions?.idFoto === item.idFoto) {
      setItemShowOptions(null);
      setShowOptionsCard(false);
    } else {
      setShowOptionsCard(true);
      setItemShowOptions(item);
      setItemSelecionado(null);
    }
  }

  /**
   * Ação ao clicar na foto
   */
  function clickFoto(item: Foto) {
    setShowOptionsOrdenar(false);
    if (itemShowOptions && itemShowOptions?.idFoto === item.idFoto) {
      setItemShowOptions(null);
      setShowOptionsCard(false);
      return;
    }

    if (itemSelecionado && itemSelecionado?.idFoto === item.idFoto) {
      setItemSelecionado(null);
    } else {
      setItemShowOptions(null);
      setShowOptionsCard(false);
      setItemSelecionado(item);
    }
  }

  /**
   * Carrega os dados
   *
   * @param {FilterModel[]} [filters=[]] - Filtros para os dados
   */
  function loadData(filters: FilterModel[] = []) {
    appLayoutActions.setLoading(true);

    apiRequestSimulation()
      .then(res => {
        appLayoutActions.addMidiaToolbar(res.data);
      })
      .catch(err => {
        appLayoutActions.addMidiaToolbar([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCarregarDados'),
          icon: 'error'
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }
}

export default Dashboard;
