import React, { useState } from 'react';
import { useFormik } from 'formik';
import Card from '@kepha/sumora-react-components/lib/card';
import TextField from '@kepha/sumora-react-components/lib/textfield';
import Button from '@kepha/sumora-react-components/lib/button';
import { useStyles } from './login.styles';
import Cabecalho from 'root-components/cabecalho/cabecalho';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Authentication from 'root-resources/oauth/authentication';
import Swal from 'root-components/swal/swal';
import { useHistory } from 'react-router-dom';
import ExceptionEnum from 'root-resources/exception-enum';
import ClienteAPI from 'root-resources/api/cliente';

export type LoginPropType = {};

/**
 * View Login
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function Login(props: LoginPropType): JSX.Element {
  const oauth = new Authentication();
  const clienteAPI = new ClienteAPI();

  const [statusLogin, setStatusLogin] = useState(true);
  const [statusCadastrar, setStatusCadastrar] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles(props);
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES });
  const history = useHistory();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched
  } = useFormik({
    initialValues: {
      nome: '',
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      nome: statusCadastrar
        ? Yup.string().required(formatMessage('login.campoObrigatorio'))
        : Yup.string(),
      email: Yup.string()
        .email(formatMessage('login.emailInvalido'))
        .required(formatMessage('login.campoObrigatorio')),
      password:
        statusLogin && !statusCadastrar
          ? Yup.string()
              .min(6, formatMessage('login.minimoSenha'))
              .required(formatMessage('login.campoObrigatorio'))
          : Yup.string()
    }),
    onSubmit: () => {
      if (statusLogin) {
        if (statusCadastrar) {
          submitCadastrar();
        } else {
          submitLogin();
        }
      } else {
        submitResetPassword();
      }
    }
  });

  return (
    <div className={classes.background}>
      <Cabecalho />

      <Card className={classes.card}>
        <div className={classes.title}>{!statusCadastrar ? 'Login' : 'Cadastre-se'}</div>

        <Grid className={classes.grid} container spacing={3}>
          {statusCadastrar && (
            <Grid item xs={12} className={classes.textField}>
              <TextField
                type='nome'
                name='nome'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nome}
                error={touched.nome && errors.nome !== undefined}
                helperText={touched.nome && errors.nome !== '' ? errors.nome : null}
                variant='outlined'
                placeholder='Nome'
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.textField}>
            <TextField
              type='email'
              name='email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email !== undefined}
              helperText={touched.email && errors.email !== '' ? errors.email : null}
              variant='outlined'
              placeholder='E-mail'
              InputProps={{
                className: classes.input
              }}
            />
          </Grid>

          {!statusCadastrar && (
            <div className={classes.textPassword} style={{ maxHeight: statusLogin ? 110 : 0 }}>
              <Grid item xs={12} className={classes.textField}>
                <TextField
                  type='password'
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && errors.password !== undefined}
                  helperText={touched.password && errors.password !== '' ? errors.password : null}
                  value={values.password}
                  variant='outlined'
                  placeholder='Senha'
                  InputProps={{
                    className: classes.input
                  }}
                />
              </Grid>
            </div>
          )}

          {!statusCadastrar && (
            <div className={classes.esqueceuSenha}>
              {statusLogin ? (
                <label className={classes.label} onClick={forgetPassword}>
                  {formatMessage('login.esqueceuSenha')}
                </label>
              ) : (
                <label className={classes.label} onClick={backLogin}>
                  {formatMessage('login.retornarLogin')}
                </label>
              )}

              <div className={classes.button}>
                <Button
                  className={classes.labelButton}
                  CircularProgressProps={{ color: 'primary' }}
                  loading={loading}
                  disabled={loading}
                  style={{ padding: loading ? 0 : '8px 16px' }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {statusLogin ? formatMessage('login.login') : formatMessage('login.enviar')}
                </Button>
              </div>
            </div>
          )}

          {statusCadastrar && (
            <div className={classes.esqueceuSenha} style={{ justifyContent: 'flex-end' }}>
              <div className={classes.button}>
                <Button
                  className={classes.labelButton}
                  CircularProgressProps={{ color: 'primary' }}
                  loading={loading}
                  disabled={loading}
                  style={{ padding: loading ? 0 : '8px 16px', width: 90 }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Cadastrar
                </Button>
              </div>
            </div>
          )}
        </Grid>

        <div className={classes.cadastrar}>
          <div onClick={clickCadastrarSe} className={classes.clickCadastrar}>
            {!statusCadastrar ? (
              <div>
                ou <u style={{ padding: '0 5px 0 5px' }}>Cadastre-se</u>{' '}
              </div>
            ) : (
              <div>
                ou <u style={{ padding: '0 5px 0 5px' }}>Entre</u> se já tem um cadastro
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );

  /**
   * Altera valores na tela para usuário se cadastrar
   *
   */
  function clickCadastrarSe() {
    setFieldValue('email', '');
    setFieldValue('password', '');
    setFieldError('email', '');
    setFieldTouched('email', false);
    setStatusLogin(true);
    setStatusCadastrar(!statusCadastrar);
  }

  /**
   * Envia o email para cadastrar novo usuário
   *
   */
  function submitCadastrar() {
    setLoading(true);

    clienteAPI
      .saveCliente(values.nome, values.email)
      .then(() => {
        Swal({
          showConfirmButton: true,
          showCancelButton: false,
          title: formatMessage('login.emailEnviadoMessage') + values.email,
          text: formatMessage('login.emailEnviadoConfirmar'),
          icon: 'success'
        }).then(() => {
          setFieldValue('email', '');
          setFieldValue('password', '');
          setFieldError('email', '');
          setFieldTouched('email', false);
          setStatusCadastrar(!statusCadastrar);
          setLoading(false);
        });
      })
      .catch(err => {
        setLoading(false);

        if (err.response?.data.code === ExceptionEnum.EMAIL_DUPLICADO) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRealizarLogin'),
            text: formatMessage('login.emailDuplicado'),
            icon: 'error'
          });
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('login.falhaRealizarLogin'),
          text: formatMessage('login.falhaLoginMessage'),
          icon: 'error'
        });
      });
  }

  /**
   * Realiza a validação do login
   *
   */
  function submitLogin() {
    setLoading(true);

    oauth
      .login(values.email, values.password)
      .then(() => {
        history.push('/');
      })
      .catch(err => {
        setLoading(false);

        if (err.response?.data.code === ExceptionEnum.USUARIO_INATIVO) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRealizarLogin'),
            text: formatMessage('login.falhaUsuarioInativo'),
            icon: 'error'
          });
          return;
        }

        if (err.response?.data.code === ExceptionEnum.LOGIN_INVALIDO) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRealizarLogin'),
            text: formatMessage('login.usuarioSenhaIncorreto'),
            icon: 'error'
          });
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('login.falhaRealizarLogin'),
          text: formatMessage('login.falhaLoginMessage'),
          icon: 'error'
        });
      });
  }

  /**
   * Realiza a validação do login
   *
   * @param values
   */
  function submitResetPassword() {
    setLoading(true);

    oauth
      .recuperarSenha(values.email)
      .then(() => {
        Swal({
          showConfirmButton: true,
          showCancelButton: false,
          title: formatMessage('login.emailEnviadoMessage') + values.email,
          text: formatMessage('login.emailEnviado'),
          icon: 'success'
        });
        setLoading(false);
      })
      .catch(err => {
        if (err.response?.data.code === ExceptionEnum.EMAIL_INEXISTENTE) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('login.falhaRecuperarSenha'),
            text: formatMessage('login.usuarioNaoEncontrado'),
            icon: 'error'
          });
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('login.falhaRecuperarSenha'),
          text: formatMessage('login.falhaRecuperarSenhaMensagem'),
          icon: 'error'
        });

        setLoading(false);
      });
  }

  /**
   * Realiza o efeito de aumentar o card
   *
   * @param {React.MouseEvent<HTMLLabelElement, MouseEvent>} event
   */
  function backLogin(event: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    setStatusLogin(true);
  }

  /**
   * Realiza o efeito de diminuir o card
   *
   * @param {React.MouseEvent<HTMLLabelElement, MouseEvent>} event
   */
  function forgetPassword(event: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    setStatusLogin(false);
  }
}

export default Login;
