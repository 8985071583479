import React, { useState } from 'react';
import { useStyles } from './modal-colecao-grupo.styles';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import ptBR from './i18n/ptBR';
import { TextField, Button } from '@kepha/sumora-react-components/lib';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import GrupoColecaoApi from 'root-resources/api/grupo-colecao';
import ColecaoApi from 'root-resources/api/colecao';
import { DispatchAction } from 'root-states/root-dispatcher';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import Swal from 'root-components/swal/swal';

export type ModalColecaoGrupoPropType = {
  typeCard: number;
  closeModal: () => void;
};

/**
 * Tipo dos valores do formik
 */
export type ModalFormikValuesType = {
  nmColecao: string;
};

/**
 * Modal para cadastro de grupo ou coleção
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {ModalColecaoGrupoPropType} props
 */
function ModalColecaoGrupo(props: ModalColecaoGrupoPropType): JSX.Element {
  const classes = useStyles(props);
  const history = useHistory();
  const grupoColecaoApi = new GrupoColecaoApi();
  const colecaoApi = new ColecaoApi();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const { typeCard, closeModal } = props;
  const [loading, setLoading] = useState(false);
  const formatMessage = useMessages({ 'pt-BR': ptBR });

  const { values, errors, touched, handleBlur, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      nmColecao: ''
    },
    validationSchema: Yup.object().shape({
      nmColecao: Yup.string().required(formatMessage('modal.campoObrigatorio'))
    }),
    onSubmit: () => submit(),
    validateOnBlur: true,
    validateOnChange: false
  });

  return (
    <div className={classes.cardAll}>
      <div className={classes.criarNova}>
        {formatMessage(typeCard ? 'modal.criarNovoGrupo' : 'modal.criarNovaColecao')}
      </div>

      <div className={classes.cardTextField}>
        <TextField
          value={values.nmColecao}
          placeholder={formatMessage(typeCard ? 'modal.digiteNomeGrupo' : 'modal.digiteNomeColecao')}
          variant='outlined'
          onChange={e => setFieldValue('nmColecao', e.target.value)}
          onBlur={handleBlur}
          InputProps={{
            className: classes.input
          }}
          error={touched.nmColecao && errors.nmColecao !== undefined}
          helperText={touched.nmColecao && errors.nmColecao !== '' ? errors.nmColecao : null}
        />
      </div>

      <div className={classes.labelObs}>{typeCard ? formatMessage('modal.observacaoGrupos') : ''}</div>

      <div className={classes.buttons}>
        <Button
          className={classes.labelButton}
          CircularProgressProps={{ color: 'primary' }}
          style={{ width: 80 }}
          onClick={() => !loading && closeModal()}
        >
          {formatMessage('modal.cancelar')}
        </Button>

        <div style={{ width: 25 }} />

        <Button
          className={classes.labelButton}
          CircularProgressProps={{ color: 'primary' }}
          loading={loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          {formatMessage('modal.criar')}
        </Button>
      </div>
    </div>
  );

  /**
   * Cria uma nova coleção
   */
  function submit() {
    setLoading(true);

    if (typeCard) {
      grupoColecaoApi
        .save({ nmGrupoColecao: values.nmColecao })
        .then(res => {
          appLayoutActions.addMidiaToolbar([]);
          closeModal();
          history.push(`/app/grupo/${res.data.idGrupoColecao}`);
        })
        .catch(() => {
          closeModal();
          tratarMensagensErro(formatMessage('modal.erroSalvarGrupo') + ' ' + values.nmColecao);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      colecaoApi
        .save({ nmColecao: values.nmColecao })
        .then(res => {
          appLayoutActions.addMidiaToolbar([]);
          closeModal();
          history.push(`/app/colecao/${res.data.idColecao}`);
        })
        .catch(() => {
          closeModal();
          tratarMensagensErro(formatMessage('modal.erroSalvarGrupo') + ' ' + values.nmColecao);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  /**
   * Mensagem retornada em casoso de erro
   */
  function tratarMensagensErro(mensagemDetalhes: string) {
    Swal({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Ok',
      title: formatMessage('modal.erroSalvar'),
      text: mensagemDetalhes,
      icon: 'error'
    });
  }
}

export default ModalColecaoGrupo;
