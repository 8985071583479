import { makeStyles } from '@material-ui/core';

/**
 * Estilos do componente de modal
 */
const useStyles = makeStyles({
  backgroundModal: {
    width: 'calc(100% + 270px)',
    height: 'calc(100vh + 50px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    marginLeft: -270,
    marginTop: -50,
    overflow: 'hidden',
    zIndex: 1200,
    backgroundColor: 'rgba(15, 15, 15, 0.8)',
  },
  backgroundModalClick: {
    width: 'calc(100% + 270px)',
    height: 'calc(100vh + 50px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: 1200,
    marginLeft: -270,
    marginTop: -50
  },
  cardModal: {
    color: 'rgb(255, 255, 255)',
    height: 246,
    width: 475,
    zIndex: 1300,
    backgroundColor: '#242424'
  }
});

export { useStyles };
