import Dashboard from 'root-views/dashboard/dashboard';
import AlterarSenha from 'root-views/perfil/alterarsenha/alterar-senha';
import DadosPerfil from 'root-views/perfil/dadosperfil/dados-perfil';
import AppConfiguracao from  'root-views/perfil/appconfiguracao/app-configuracao';
import PlanosAssinaturas from 'root-views/perfil/planosassinaturas/planos-assinaturas';

export type ScreenRoutesType = {
  path: string;
  exactPath?: boolean;
  component: (props: any) => JSX.Element;
};

/**
 * Constante com as definições das rotas das Screens/Views/Telas da aplicação
 */
const viewsRoutes: ScreenRoutesType[] = [
  {
    path: '/app',
    exactPath: true,
    component: Dashboard,
  },
  {
    path: '/app/grupo/:id',
    exactPath: true,
    component: Dashboard,
  },
  {
    path: '/app/colecao/:id',
    exactPath: true,
    component: Dashboard,
  },
  {
    path: '/app/perfil/senha',
    exactPath: true,
    component: AlterarSenha,
  },
  {
    path: '/app/perfil/dados',
    exactPath: true,
    component: DadosPerfil,
  },
  {
    path: '/app/perfil/configuracao',
    exactPath: true,
    component: AppConfiguracao,
  },
  {
    path: '/app/perfil/planos',
    exactPath: true,
    component: PlanosAssinaturas,
  },
];

export default viewsRoutes;
