export default {
  usuario: {
    usuarios: 'Usuários',
    cadastroDeUsuario: 'Cadastro de usuário',
    edicaoDeUsuario: 'Edição de usuário',
    desejaDeletarEsteUsuario: 'Deseja deletar este usuário?',
    usuarioDeletadoComSucesso: 'Usuário deletado com sucesso',
    representante: 'Representante',
    gerente: 'Gerente',
    tecnico: 'Técnico',
    produtor: 'Produtor',
    tipoDeUsuario: 'Tipo de usuário',
    estaColecaoVazia: 'Esta Coleção está vazia',
    crieUmaMidia: 'Crie uma nova Mídia ou “Mova” uma Mídia para esta coleção',
    novaMidia: 'Nova Mídia',
    esteGrupoVazio: 'Este Grupo está vazio',
    crieUmaColecao: '“Mova” as suas coleções ou crie uma nova coleção neste grupo',
    novaColecao: 'Nova Coleção',
  },
};
